import React from 'react'

import { Payload, Status } from '@components/data-handler/data-handler'

function useDataHandlerAllComplete<T extends Omit<Payload, 'data'>>(
  payloads: T[]
) {
  const activePayload = React.useMemo(() => {
    if (payloads.every((payload) => payload.status === Status.FULFILLED)) {
      return payloads[0]
    }

    const anyRejectedPayload = payloads.find(
      (payload) => payload.status === Status.REJECTED
    )
    if (anyRejectedPayload) {
      return anyRejectedPayload
    }

    return payloads.find(
      (payload) =>
        payload.status !== Status.FULFILLED &&
        payload.status !== Status.REJECTED
    ) as T
  }, [payloads])

  return {
    ...activePayload,
    isAllComplete: payloads.every(
      (payload) => payload.status === Status.FULFILLED
    ),
  }
}

export default useDataHandlerAllComplete
