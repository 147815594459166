import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { TokenInterface } from '@store/actionSlices/token'
import type { ProjectIdentity, RootStateTypeExtra, User } from '@store/types'

import { hasToken } from '@utilities/token-helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

export type SessionActiveRoutes = {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  user: User
  token: TokenInterface
  returnPath: string
  authenticationPath: string
} & RouteProps

function SessionActiveRoutes({
  session,
  user,
  projectIdentity,
  token,
  returnPath,
  authenticationPath,
  ...routeProps
}: SessionActiveRoutes) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true)
  const [isSessionActive, setSessionActive] = React.useState(true)

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  React.useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  React.useEffect(() => {
    const { sessionId, sessionList } = projectIdentity
    if (!sessionId && sessionList.length === 0 && !session?.connected) {
      setSessionActive(false)
    }
  }, [projectIdentity, session])

  const setSessionOwner = async () => {
    const now = new Date()
    await firebaseControlQuery.updateSessionOwner([
      ...(session?.sessionOwners || []),
      {
        name: user.name,
        email: user.email,
        lastUpdated: now.toString(),
      },
    ])
  }

  React.useEffect(() => {
    if (session?.sessionOwners) {
      if (!session?.sessionOwners.find((res) => res.email === user.email)) {
        setSessionOwner()
      }
    }
  }, [session])

  if (isSessionActive && isAuthenticated) {
    return <Route {...routeProps} />
  }

  if (isAuthenticated && !isSessionActive) {
    return <Redirect to={{ pathname: returnPath }} />
  }

  return <Redirect to={{ pathname: authenticationPath }} />
}

export default connect(
  ({
    projectIdentity,
    token,
    firestore: { session },
    user,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
    user,
  })
)(SessionActiveRoutes)
