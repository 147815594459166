import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  LotShortlistInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
} from '@store/types'

import { Modal } from '@components/modals'

import {
  ExtendedLotInterface,
  LotInterface,
  PackageInterface,
} from '@api/houseAndLand'

import { numberWithCommas } from '@utilities/helper'
import useParseLotPackageConfiguration from '@utilities/lot-package-util'
import ShortlistHandler from '@utilities/shortlist-handler'

import { StarSvg } from '@svg/react'

export interface LotCardProps {
  lot: ExtendedLotInterface
  package?: PackageInterface
  showPrice: boolean
  hideSoldPrice: boolean
  setShortlistErrorMessage: (arg: string) => void
  trigger?: (lot: LotInterface) => void
  hideLotStatus?: boolean
  disableSoldLot?: boolean
  shortlist: ShortlistState
  enableUnshortlistAll?: boolean
  projectIdentity: ProjectIdentity
}

function LotCard({
  lot,
  package: lotPackage,
  showPrice,
  hideSoldPrice,
  setShortlistErrorMessage,
  trigger,
  hideLotStatus,
  disableSoldLot,
  shortlist,
  enableUnshortlistAll,
  projectIdentity,
}: LotCardProps) {
  const [showConfirmModal, toggleConfirmModal] = useState(false)
  const [removeShortlistProperty, setRemoveShortlistProperty] = useState<
    boolean | 'all'
  >(false)

  const [parseLotPackageConfiguration] = useParseLotPackageConfiguration()
  const { configurationTitle, price } = parseLotPackageConfiguration({
    lot,
    package: lotPackage,
    configuration: lot.configuration,
  })

  const isLotSold = useMemo<boolean>(
    () => lot.status !== projectIdentity?.statusLabels?.available,
    [lot, projectIdentity]
  )

  const property = useMemo<LotShortlistInterface | undefined>(
    () => ({
      propertyId: lot.id,
      propertyName: lot.name,
      references: {
        precinctId: lot.precinctId,
        stageId: lot.stageId,
      },
      configuration: lot.configuration,
      type: ShortlistPropertyType.Lot,
    }),
    [lot]
  )

  const [isShortlisted, handleShortlistClick] =
    (property &&
      ShortlistHandler({
        property,
        shortlist,
        setShortlistErrorMessage,
        projectIdentity,
        toggleConfirmModal,
        removeShortlistProperty,
        setRemoveShortlistProperty,
        available: lot.status,
      })) ||
    []

  const shouldShowPrice =
    showPrice &&
    !(lot?.status !== projectIdentity?.statusLabels?.available && hideSoldPrice)

  return (
    <>
      <div
        className={`${isLotSold ? 'opacity-60' : ''} ${
          isLotSold && disableSoldLot ? 'pointer-events-none' : ''
        } relative w-full cursor-pointer rounded-lg bg-gradient p-5 text-neutralColour shadow-standard`}
      >
        <div
          onClick={() => trigger && trigger(lot)}
          role="none"
          className="w-full"
        >
          <div className="mb-4 flex items-center justify-between">
            <div className="truncate pr-8 text-heading font-medium leading-none">
              Lot {lot.name}
            </div>

            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleShortlistClick?.()
              }}
            >
              {lot.status === projectIdentity?.statusLabels?.available ? (
                <StarSvg
                  size="m"
                  className={`h-8 w-8 transition-all ${
                    isShortlisted
                      ? 'stroke-amber-500 text-amber-500'
                      : 'stroke-neutralColour text-transparent'
                  }`}
                  stroke={isShortlisted ? 0 : 2}
                />
              ) : (
                <StarSvg
                  size="m"
                  className="h-8 w-8 stroke-neutralColour text-transparent"
                  stroke={2}
                />
              )}
            </button>
          </div>
          <div className="flex items-center justify-between text-default">
            <span>
              {Number(price || lot?.price) > 0 &&
                shouldShowPrice &&
                `$${numberWithCommas(String(price || lot?.price))}`}
            </span>
            {!hideLotStatus && <span className="capitalize">{lot.status}</span>}
          </div>
          {configurationTitle ? (
            <div className="mt-2 break-words border-t border-[#808080] py-3">
              <p className="italic">{configurationTitle}</p>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        isVisible={showConfirmModal}
        toggleModal={toggleConfirmModal}
        modalWidth="max-w-lg"
        title="Remove from shortlist"
        applyButtonText="Remove"
        closeButtonText="Cancel"
        handleApply={() => {
          setRemoveShortlistProperty(enableUnshortlistAll ? 'all' : true)
        }}
        titleClassName="text-center text-heading leading-[120%] text-mainColour"
        footerClassName="mt-5 flex flex-row-reverse items-center justify-between  gap-[15px] border-gray-200"
        cancelClassName="w-full rounded-lg border py-3 text-subHeading bg-mainColour font-medium text-white"
        okayClassName="w-full rounded-lg border py-3 text-subHeading font-normal text-[#FF0000]"
      >
        <div className="mb-12 mt-5">
          <p className="leading-[120%] text-fontBlackColour opacity-60">
            Are you sure you want to remove this from your shortlist?
          </p>
          <p className="mt-5 font-[600] italic leading-[120%] text-fontBlackColour opacity-60">
            {`Lot ${lot.name}`}
            {configurationTitle ? <>: {configurationTitle}</> : null}
          </p>
        </div>
      </Modal>
    </>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
  })
)(LotCard)
