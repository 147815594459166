import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  ProjectIdentity,
  RootStateTypeExtra,
  TabInterface,
} from '@store/types'

import { GalleryItem, GalleryTab } from '@components/gallery-handler/elements'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useScrollEffect from '@hooks/useScrollEffect'

interface GallerySessionInterface {
  galleryControl: GalleryControlInterface
  playerControl?: PlayerControlInterface
}

interface GalleryHandlerInterface {
  galleryName: string
  galleries: Array<GalleryInterface>
  gallerySession: GallerySessionInterface | undefined
  showPlayButton?: boolean
  gridColumnClass?: string
  showTab?: boolean
  projectIdentity: ProjectIdentity
  onVideoPlayback?: (videoUrl: string) => void
  slideView?: boolean
  previousRoute: string
}

function GalleryHandler({
  galleryName,
  galleries,
  gallerySession,
  showPlayButton,
  gridColumnClass = 'grid-cols-3',
  showTab = true,
  projectIdentity,
  onVideoPlayback,
  slideView,
  previousRoute,
}: GalleryHandlerInterface) {
  const TAB_GAP = 15
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeGalleryTabIndex, setActiveGalleryTabIndex] =
    React.useState<number>(0)
  const [activeGalleryItemIndex, setActiveGalleryItemIndex] =
    React.useState<number>(0)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] =
    React.useState<boolean>(false)
  const [tabWidth, setTabWidth] = React.useState<number>(0)
  const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false)
  const [tabs, setTabs] = React.useState<Array<TabInterface>>([])

  const slideShowPlayingStateRef = React.useRef<boolean>()
  const videoPlayerStateRef = React.useRef<string>()

  const activeGalleryItems = (): Array<GalleryItemInterface> =>
    (showTab ? galleries[activeGalleryTabIndex]?.items : galleries[0].items) ||
    []

  const buildTab = () => {
    const myTabs: TabInterface[] = []
    galleries.forEach((gallery: GalleryInterface, index: number) => {
      if (gallery.label && gallery.label !== '') {
        myTabs.push({ index, label: gallery.label })
      }
    })
    setTabs(myTabs)
  }

  const handleTabChange = async (tabIndex: number) => {
    await firebaseControlQuery.update({
      [`${galleryName}.galleryControl.activeTabIndex`]: tabIndex,
      [`${galleryName}.galleryControl.activeItemID`]: 0,
      [`${galleryName}.galleryControl.isPlaying`]: false,
    })
  }

  const handleItemClick = async (itemId: string, type: string) => {
    const selectedItemIndex = activeGalleryItems()?.findIndex(
      (item) => item.id === itemId
    )

    onVideoPlayback?.(
      type === 'video'
        ? String(activeGalleryItems()?.[selectedItemIndex]?.videoSource)
        : ''
    )

    const controlVideoPlayback =
      !isVideoPlaying ||
      activeGalleryItems()?.[selectedItemIndex]?.id !== itemId
        ? 'play'
        : 'pause'

    if (type === 'video' && activeGalleryItemIndex !== selectedItemIndex) {
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.currentVideoPositionInSeconds`]: 0,
        [`${galleryName}.playerControl.scrubPositionInSeconds`]: 0,
      })
    }

    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.isPlayerVisible`]: type === 'video',
      [`${galleryName}.playerControl.playerState`]:
        type === 'video' ? controlVideoPlayback : 'stop',
      [`${galleryName}.galleryControl.isPlaying`]: false,
      [`${galleryName}.galleryControl.activeItemID`]: itemId,
    })
  }

  const handleTabWidth = () => {
    const galleryItemElem = document.querySelector('.gallery-item')
    if (galleryItemElem) {
      const computedStyle = window.getComputedStyle(galleryItemElem)
      const width = parseFloat(computedStyle.width)
      setTabWidth(width * 2 + TAB_GAP)
    }
  }

  const handleScrubBarPositionUpdate = (
    playerControl: PlayerControlInterface
  ) => {
    const { videoSynchronizeTriggerKey } = playerControl
    const isPreviousRouteValid =
      previousRoute &&
      (previousRoute === '/session-settings' || previousRoute === '/tools')

    const shouldUpdatePosition = playerControl?.playerState === 'play'

    if (isPreviousRouteValid && shouldUpdatePosition) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: true,
      })
    }

    if (videoSynchronizeTriggerKey) {
      firebaseControlQuery.update({
        [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: false,
        previousRoute: '',
      })
    }
  }

  React.useEffect(() => {
    buildTab()
  }, [galleries])

  React.useEffect(() => {
    if (gallerySession) {
      const {
        galleryControl: {
          activeItemID: activeItemIDFirebase,
          activeTabIndex: activeTabIndexFirebase,
          isPlaying: isPlayingFirebase,
        },
        playerControl,
      } = gallerySession

      const activeItemIndexFirebase = galleries?.[
        activeTabIndexFirebase
      ]?.items.findIndex((item) => item.id === activeItemIDFirebase)
      setActiveGalleryItemIndex(
        activeItemIndexFirebase !== -1 ? activeItemIndexFirebase : 0
      )
      setActiveGalleryTabIndex(activeTabIndexFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
      slideShowPlayingStateRef.current = isPlayingFirebase

      setIsVideoPlaying(playerControl?.playerState === 'play')
      videoPlayerStateRef.current = playerControl?.playerState
    }
  }, [
    gallerySession?.galleryControl?.activeItemID,
    gallerySession?.galleryControl?.activeTabIndex,
    gallerySession?.galleryControl?.isPlaying,
    gallerySession?.playerControl?.playerState,
  ])

  React.useEffect(() => {
    if (gallerySession?.playerControl) {
      handleScrubBarPositionUpdate(gallerySession?.playerControl)
    }
  }, [
    gallerySession?.playerControl?.playerState,
    gallerySession?.playerControl?.videoSynchronizeTriggerKey,
  ])

  React.useEffect(() => {
    if (galleryName === 'videoGallery') {
      return
    }

    if (isSlideShowPlaying) {
      setActiveGalleryItemIndex(-1)
    }
  }, [isSlideShowPlaying])

  React.useEffect(() => {
    window.addEventListener('resize', handleTabWidth)

    return () => {
      window.removeEventListener('resize', handleTabWidth)
    }
  }, [])

  useScrollEffect(activeGalleryTabIndex)

  const updateGalleryPlayerControl = () => {
    if (galleryName === 'videoGallery') {
      const isPlayerVisible = videoPlayerStateRef.current !== 'stop'

      firebaseControlQuery.update({
        [`${galleryName}.playerControl.isPlayerVisible`]: isPlayerVisible,
        [`${galleryName}.playerControl.playerState`]:
          videoPlayerStateRef.current,
        [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: false,
      })
      return
    }

    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.isPlaying`]:
        slideShowPlayingStateRef.current,
    })
  }

  React.useEffect(() => () => updateGalleryPlayerControl(), [])

  return slideView ? (
    <div className="h-full w-full">
      <CarouselProvider
        naturalSlideWidth={125}
        naturalSlideHeight={125}
        dragEnabled={false}
        disableKeyboard
        lockOnWindowScroll
        touchEnabled={false}
        totalSlides={galleries.length}
        currentSlide={activeGalleryTabIndex}
        className="flex h-full w-full flex-col"
        isIntrinsicHeight
      >
        <GalleryTab
          tabs={tabs}
          activeGalleryTabIndex={activeGalleryTabIndex}
          onTabChange={handleTabChange}
          className="mb-[15px] flex-shrink-0"
          tabWidth={tabWidth}
        />

        <Slider
          className="flex-1"
          classNameTray="!duration-[460ms] h-full"
          classNameTrayWrap="h-full"
        >
          {galleries.map((gallery, galleryIndex) => (
            <Slide
              key={gallery.label}
              index={galleryIndex}
              className="no-scrollbar overflow-y-auto"
            >
              <div className={`grid ${gridColumnClass} gap-3 px-5`}>
                {gallery.items?.map(
                  (galleryItem: GalleryItemInterface, index: number) => (
                    <GalleryItem
                      key={`gallery-item-${index.toString()}`}
                      galleryData={{ index, item: galleryItem }}
                      isActiveItem={
                        activeGalleryItemIndex === index &&
                        activeGalleryTabIndex === galleryIndex
                      }
                      onItemClick={(_, type) =>
                        handleItemClick(galleryItem.id, type)
                      }
                      showPlayButton={showPlayButton || false}
                      setTabWidth={setTabWidth}
                      isVideoPlaying={isVideoPlaying}
                    />
                  )
                )}
              </div>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  ) : (
    <>
      {showTab && (
        <div
          className={`gallery flex w-full items-center ${
            tabs.length > 1 ? 'justify-between' : 'justify-end'
          }`}
        >
          {tabs.length > 1 && (
            <GalleryTab
              tabs={tabs}
              activeGalleryTabIndex={activeGalleryTabIndex}
              onTabChange={handleTabChange}
              className="mb-[15px]"
            />
          )}
        </div>
      )}

      <div className={`grid ${gridColumnClass} gap-[14px]`}>
        {activeGalleryItems()?.map(
          (galleryItem: GalleryItemInterface, index: number) => (
            <GalleryItem
              key={`gallery-item-${index.toString()}`}
              galleryData={{ index, item: galleryItem }}
              isActiveItem={activeGalleryItemIndex === index}
              onItemClick={(_, type) => handleItemClick(galleryItem.id, type)}
              showPlayButton={showPlayButton || false}
              isVideoPlaying={isVideoPlaying}
            />
          )
        )}
      </div>
    </>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(GalleryHandler)
