import React from 'react'

interface VerticalScrollContainerProps {
  children: React.ReactNode
  className?: string
}

function VerticalScrollContainer({
  children,
  className,
}: VerticalScrollContainerProps) {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [mouseDown, setMouseDown] = React.useState(false)
  const startY = React.useRef(0)
  const scrollTop = React.useRef(0)

  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDown(true)
    if (containerRef.current) {
      startY.current = e.pageY - containerRef.current.offsetTop
      scrollTop.current = containerRef.current.scrollTop
    }
  }

  const stopDragging = () => {
    setMouseDown(false)
  }

  const move = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!mouseDown || !containerRef.current) return
    const y = e.pageY - containerRef.current.offsetTop
    const scroll = y - startY.current
    containerRef.current.scrollTop = scrollTop.current - scroll
  }

  return (
    <div
      className={`${className} no-scrollbar`}
      role="button"
      tabIndex={0}
      onMouseMove={move}
      onMouseDown={startDragging}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
      ref={containerRef}
      style={{
        WebkitTapHighlightColor: 'transparent',
      }}
    >
      {children}
    </div>
  )
}

export default VerticalScrollContainer
