import React from 'react'

import { MenuItemInterface } from '@store/types'

import { SessionSettingsModalAction } from '@components/session-settings'
import { ToolsModalAction } from '@components/tools'

export enum MenuItemKey {
  Residences = 'residences',
  InteractiveMap = 'interactive-map',
  Videos = 'videos',
  Gallery = 'gallery',
  Brochure = 'brochure',
  Team = 'team',
  ExternalRoutes = 'external-routes',
  Shortlist = 'shortlist',
  SessionSettings = 'session-settings',
  Tools = 'tools',
}

const MENU_ITEMS: Array<MenuItemInterface> = [
  {
    key: MenuItemKey.Residences,
    label: 'Residences',
    route: 'area-view',
    relatedRoutes: [
      'area-view',
      'level-view',
      'building',
      'snaploader-view',
      '3d-building',
      'panoramic-view',
      'panoramic',
      'area-view-house-and-land',
      'precinct',
      'stages',
      'lot-view',
    ],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 1,
  },
  {
    key: MenuItemKey.InteractiveMap,
    label: 'Map',
    route: 'interactive-map',
    relatedRoutes: ['interactive-map'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 2,
  },
  {
    key: MenuItemKey.Videos,
    label: 'Videos',
    route: 'video-gallery',
    relatedRoutes: ['video-gallery'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 3,
  },
  {
    key: MenuItemKey.Gallery,
    label: 'Gallery',
    route: 'gallery',
    relatedRoutes: ['gallery'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 4,
  },
  {
    key: MenuItemKey.Brochure,
    label: 'Brochure',
    route: 'brochure',
    relatedRoutes: ['brochure'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 5,
  },
  {
    key: MenuItemKey.Team,
    label: 'Team',
    route: 'teams',
    relatedRoutes: ['teams'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 6,
  },
  {
    key: MenuItemKey.ExternalRoutes,
    label: 'External links',
    route: 'external-views',
    relatedRoutes: ['external-views'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 7,
  },
  {
    key: MenuItemKey.Shortlist,
    label: 'Shortlist',
    route: 'shortlist',
    relatedRoutes: ['shortlist'],
    active: true,
    type: 'main-menu',
    routeViaFirebase: true,
    order: 8,
  },
  {
    key: MenuItemKey.SessionSettings,
    label: 'Session',
    active: true,
    type: 'footer-menu',
    order: 9,
    render: ({ menu, key, style, tabIndex, className }) => (
      <SessionSettingsModalAction
        key={key}
        className={className}
        style={style}
        tabIndex={tabIndex}
      >
        {menu.label}
      </SessionSettingsModalAction>
    ),
  },
  {
    key: MenuItemKey.Tools,
    label: 'Tools',
    active: true,
    type: 'footer-menu',
    order: 10,
    render: ({ menu, key, style, tabIndex, className }) => (
      <ToolsModalAction
        key={key}
        className={className}
        style={style}
        tabIndex={tabIndex}
      >
        {menu.label}
      </ToolsModalAction>
    ),
  },
]

export default MENU_ITEMS
