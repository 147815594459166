import React from 'react'
import { useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { ProjectIdentity } from '@store/types'

import { Modal } from '@components/modals'
import SessionField from '@components/session-field'

import { Pagination, ProjectListDetails } from '@api/project-list'

import InputHandler from '@utilities/form-util'
import { isValidSessionId } from '@utilities/helper'
import Throttle from '@utilities/throttle'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ArrowRefreshSvg, ChevronSvg, ChevronUpDownSvg } from '@svg/react'

import constants from './constants'

function CreateSessionHandler({
  projectList,
  pagination,
  isActive,
  setPage,
  projectIdentity,
  setProjectQuery,
  pageCount,
}: {
  projectIdentity: ProjectIdentity
  projectList: Array<ProjectListDetails>
  pagination: Pagination
  isActive: boolean
  pageCount: number
  setPage: (status: 'prev' | 'next') => void
  setProjectQuery: (query: string) => void
}) {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [isModalActive, modalToggle] = React.useState(false)
  const [selectProject, setSelectProject] = React.useState(false)
  const [formValid, setFromValid] = React.useState(false)

  const [canonicalName, projectCanonicalName] = React.useState('')
  const query = Throttle(canonicalName, 1000)

  const [isConnecting, setIsConnecting] = React.useState(false)

  const [sessionId, setSessionId] = React.useState('')
  const [isSessionValid, setSessionValid] = React.useState(true)

  const [project, setProject] = React.useState({
    id: projectIdentity.projectId,
    name: projectIdentity.projectId,
    label: projectIdentity.projectLabel,
    uuid: projectIdentity.projectUUID,
  })

  const [sessionName, setSessionName] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  React.useEffect(() => {
    setProject({
      id: projectIdentity.projectId,
      name: projectIdentity.projectId,
      label: projectIdentity.projectLabel,
      uuid: projectIdentity.projectUUID,
    })
    setSessionId('')
    setSelectProject(false)
    setSessionName({
      value: '',
      isValid: true,
      message: '',
    })
    projectCanonicalName('')
    setProjectQuery('')
  }, [isModalActive])

  // TODO: Duplicate session exist checking here. Need to find alternative later
  const connectToSession = async () => {
    const exist = await firebaseControlQuery.doesSessionExist({
      projectId: project.id,
      sessionId,
    })
    if (exist) {
      setIsConnecting(true)
      await firebaseControlQuery.updateConnection({
        connected: true,
        projectId: project.id,
        sessionId,
      })

      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          projectId: project.id,
          projectName: project.name,
          projectLabel: project.label,
          projectUUID: project.uuid,
          sessionId,
          sessionList: [
            ...projectIdentity.sessionList,
            {
              sessionId,
              sessionName: sessionName.value,
              projectId: project.id,
              projectName: project.name,
              projectUUID: project.uuid,
            },
          ],
        })
      )
      setSessionValid(true)
      setIsConnecting(false)
      modalToggle(false)
      window.location.reload()
      return
    }
    setSessionValid(false)
  }

  React.useEffect(() => setSessionValid(true), [sessionId])

  React.useEffect(() => {
    if (isValidSessionId(sessionId) || isConnecting) {
      setFromValid(true)
      return
    }
    setFromValid(false)
  }, [sessionId, isConnecting])

  React.useEffect(() => {
    setProjectQuery(query)
  }, [query])

  return (
    <>
      <Modal
        toggleModal={(res) => modalToggle(res)}
        isVisible={isModalActive}
        title="Add Session"
        closeButtonText="Connect →"
        modalWidth="max-w-xl"
        theme={{
          textColor: 'text-mainColour',
          mainBg: 'bg-tertiaryColour',
          secondaryBg: 'bg-neutralColour',
        }}
        hasHeaderControl
        noMainControls
      >
        {!selectProject && (
          <>
            <div className="py-4 text-mainColour">
              <div className="text-left">
                <span className="text-subHeading">Session ID*</span>
                <SessionField
                  convertToSessionId={setSessionId}
                  sessionId={sessionId}
                  className={`h-[6.5rem] bg-gray-200 text-[3.5rem] ${
                    isSessionValid ? 'border-none' : 'border-2 border-red-500'
                  } `}
                />
                {!isSessionValid && (
                  <div className="w-full text-center text-default text-red-500">
                    Invalid Session
                  </div>
                )}
              </div>
              <div className="pt-2 text-left">
                <span className="text-subHeading">Session Name (optional)</span>
                <input
                  type="text"
                  name="sessionName"
                  value={sessionName.value}
                  onChange={(e) => InputHandler(e, setSessionName)}
                  className="my-2 w-full rounded border-none bg-gray-200 px-6 py-3 text-default focus:ring-0"
                />
              </div>
              <div className="text-left">
                <span className="text-subHeading">Project Select</span>
                <button
                  type="button"
                  onClick={() => setSelectProject(true)}
                  disabled={!isActive}
                  className="my-2 ml-auto flex w-full cursor-pointer items-center rounded bg-gray-200 px-6 py-2 text-default"
                >
                  {project.label}
                  <ChevronUpDownSvg
                    width="35"
                    height="35"
                    className="ml-auto"
                  />
                </button>
              </div>
            </div>
            <button
              onClick={() => connectToSession()}
              type="button"
              disabled={!formValid}
              className={`${
                !formValid && 'cursor-not-allowed opacity-30'
              } flex w-full items-center justify-center rounded bg-gradient py-10 text-center text-subHeading text-white`}
            >
              {isConnecting ? 'Connecting' : 'Connect'}
              {isConnecting && (
                <div className="ml-2 h-7 w-7 animate-spin rounded-full border-4 border-solid border-neutralColour border-t-transparent text-center"></div>
              )}
            </button>
          </>
        )}
        {selectProject && (
          <>
            <div className="flex items-center text-default text-mainColour">
              <button
                onClick={() => setSelectProject(false)}
                type="button"
                className="flex items-center"
              >
                <ChevronSvg />
                Back
              </button>
            </div>
            <div className="py-1">
              <div
                className={`my-4 h-auto w-full transition-opacity ${
                  isActive ? 'bg-secondaryColour' : 'bg-gray-200'
                }`}
              >
                {projectIdentity.searchQuery && (
                  <div>
                    <input
                      type="text"
                      name="sessionName"
                      value={canonicalName}
                      onChange={(e) => projectCanonicalName(e.target.value)}
                      className="border-1 my-2 w-full rounded border-gray-500 text-default focus:border-gray-500 focus:ring-0"
                    />
                  </div>
                )}
                {projectList.map((prj) => (
                  <button
                    key={`${prj.id}-create-session-project`}
                    disabled={!isActive}
                    onClick={() => {
                      setProject({
                        id: prj.titleCanonical,
                        name: prj.titleCanonical,
                        label: prj.title,
                        uuid: prj.id,
                      })
                      setSelectProject(false)
                    }}
                    type="button"
                    className="flex h-auto w-full items-center border-t-2 px-2 py-4 text-subHeading text-mainColour"
                  >
                    {prj.title}
                    <ArrowRefreshSvg className="ml-auto text-sky-400" />
                  </button>
                ))}
              </div>
              {pagination.total > constants.PAGE_SIZE && (
                <div className="flex items-center">
                  <div>
                    Page <b>{pagination.page}</b> of <b>{pageCount}</b>
                  </div>
                  <div className="ml-auto">
                    <button
                      disabled={!isActive}
                      type="button"
                      onClick={() => setPage('prev')}
                      className="px-4 font-medium"
                    >
                      Prev
                    </button>
                    |
                    <button
                      disabled={!isActive}
                      type="button"
                      onClick={() => setPage('next')}
                      className="px-4 font-medium"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Modal>
      <button
        onClick={() => modalToggle(true)}
        type="button"
        className="w-full rounded bg-gradient py-6 text-center text-3xl text-neutralColour"
      >
        Add Session +
      </button>
    </>
  )
}

export default CreateSessionHandler
