import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Routes from '@src/routes'

import { setFirebaseSession } from '@store/actionSlices/firestore'
import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { TokenInterface } from '@store/actionSlices/token'
import type {
  ProjectIdentity,
  RootStateTypeExtra,
  SessionListContent,
} from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'

import {
  MasterKeyData,
  selectFromResult,
  useGetMasterKeyQuery,
} from '@api/master-key'
import { useLazyGetProjectListQuery } from '@api/project-list'

import useConnectionIndicator from '@utilities/connection-indicator'
import { getQueryStringParams } from '@utilities/helper'
import RefreshHandler from '@utilities/refresh-handler'
import styleUtil from '@utilities/style-util'
import { hasToken } from '@utilities/token-helper'
import TokenRenewalHandler from '@utilities/token-renewal-handler'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import initFirebaseSnapshot from '@firebaseUtil/firebase-snapshot'
import { SessionMap } from '@firebaseUtil/types'

interface AppProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  token: TokenInterface
  isOnline: boolean
  remoteRefreshTriggerKey: string
}

const { REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS } = process.env

function App({
  session,
  projectIdentity,
  token,
  isOnline,
  remoteRefreshTriggerKey,
}: AppProps) {
  styleUtil(projectIdentity.theme)
  const location = useLocation()
  const dispatch = useDispatch()

  useConnectionIndicator({ token, isOnline })
  TokenRenewalHandler({ token })

  const firebaseSession = initFirebaseSnapshot(
    'devsuite',
    projectIdentity.projectId,
    'session',
    projectIdentity.sessionId
  )

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  React.useEffect(() => {
    if (firebaseSession) {
      dispatch(setFirebaseSession(firebaseSession as SessionMap))
    }
  }, [firebaseSession])

  const { masterKey, sessionId } = projectIdentity
  const { _id: userId } = token

  const skipMasterKeyQuery = React.useMemo(
    () => !(hasToken(token) && masterKey && sessionId),
    [token, masterKey, sessionId]
  )

  const masterKeyPayload = useGetMasterKeyQuery(
    {
      masterKey,
      userId,
    },
    {
      selectFromResult,
      skip: skipMasterKeyQuery,
      pollingInterval:
        Number(REACT_APP_DATA_POLLING_INTERVAL_X_SECONDS || 10) * 1000,
    }
  )

  const [getProjectListTrigger] = useLazyGetProjectListQuery()

  RefreshHandler({
    refreshTriggerKeyFirebase: session?.remoteRefreshTriggerKey ?? '',
    refreshTriggerKeyLocal: remoteRefreshTriggerKey,
  })

  const handleClientCheck = (client: boolean) => {
    if (!client) {
      firebaseControlQuery
        .update({
          [`client.remote`]: true,
        })
        .catch((err) => console.error('Client check encountered an error', err))
    }
  }

  const handleMasterSessionKeys = React.useCallback(
    async (argMasterKeyData: MasterKeyData) => {
      const { sessionKeys: masterSessionKeys } = argMasterKeyData
      if (masterSessionKeys.length < 1) {
        return
      }
      const filteredMasterSessionKeys = masterSessionKeys.filter(
        (item) =>
          !projectIdentity.disconnectedSessions?.find(
            (i) => i.projectId === item.projectId && i.sessionId === item.key
          )
      )

      const promise = await Promise.all(
        filteredMasterSessionKeys.map(async (sess) => {
          const response = await getProjectListTrigger({
            email: token.email,
            page: 1,
            query: sess.projectId.replace(/[^a-zA-Z ]/g, ' '),
          }).unwrap()

          const project = response?.data?.lists?.[0]

          if (project) {
            await firebaseControlQuery.updateConnection({
              connected: true,
              projectId: sess.projectId,
              sessionId: sess.key,
            })
            return {
              projectId: sess.projectId,
              projectName: project.title,
              projectUUID: project.id,
              sessionId: sess.key,
              sessionName: '',
            }
          }
          return undefined
        })
      )

      const sessionList = promise.filter((res) => res)

      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          sessionList: sessionList as SessionListContent[],
        })
      )
    },
    [masterKeyPayload]
  )

  React.useEffect(() => {
    const { masterKeyData, isError, status } = masterKeyPayload
    if (
      isError ||
      status !== 'fulfilled' ||
      !Object.keys(masterKeyData).length
    ) {
      return
    }
    handleMasterSessionKeys(masterKeyData)
  }, [masterKeyPayload])

  React.useEffect(() => {
    if (session) {
      const {
        client: { remote },
      } = session
      handleClientCheck(remote)
    }
  }, [session])

  React.useEffect(() => {
    const searchQuery = getQueryStringParams(location.search)

    const {
      disconnect: previousDisconnectState,
      showClearCacheOption: previousClearCacheState,
      searchQuery: previousSearchQuery,
    } = projectIdentity

    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        disconnect: searchQuery?.disconnect
          ? searchQuery?.disconnect === 'true'
          : previousDisconnectState,
        searchQuery: searchQuery?.searchQuery
          ? searchQuery?.searchQuery === 'true'
          : previousSearchQuery,
        showClearCacheOption: searchQuery?.clearCache
          ? searchQuery?.clearCache === 'true'
          : previousClearCacheState,
      })
    )
  }, [])

  return (
    <>
      <Routes />
      <IdleTimeHandler />
    </>
  )
}

export default connect(
  ({
    projectIdentity,
    token,
    statusIndicator: { isOnline },
    firestore: { session },
    appConfig: { remoteRefreshTriggerKey },
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
    isOnline,
    remoteRefreshTriggerKey,
  })
)(App)
