import React from 'react'

import { HorizontalScrollContainerRef } from '@store/types'

import HorizontalScrollContainer from '@components/horizontal-scroll-container'

import useScrollEffect from '@hooks/useScrollEffect'

export interface ComponentPropsInterface {
  items: Array<string>
  activeGalleryTabIndex: number
  onTabChange: (arg: number) => void
  tabWidth?: number
  className?: string
}

function GalleryTab({
  items,
  activeGalleryTabIndex,
  onTabChange,
  className,
  tabWidth,
}: ComponentPropsInterface) {
  const horizontalScrollWrapperRef =
    React.useRef<HorizontalScrollContainerRef>()

  const handleClick = (index: number) => {
    if (
      horizontalScrollWrapperRef.current &&
      horizontalScrollWrapperRef.current.isDragging()
    ) {
      return
    }
    onTabChange(index)
  }

  useScrollEffect(activeGalleryTabIndex)

  return (
    <HorizontalScrollContainer
      className={`${className} scroll-container-${activeGalleryTabIndex}`}
      ref={horizontalScrollWrapperRef}
    >
      {items.map((label: string, index) => (
        <div className="flex last:mr-5" key={index as number}>
          <span className={`${index === 0 ? 'w-5' : 'w-[15px]'}`}></span>
          <button
            key={`gallery-tab-${index.toString()}`}
            type="button"
            className={`${
              activeGalleryTabIndex === index
                ? `active-tab-${activeGalleryTabIndex} selected-gallery-tab cursor-default bg-gradient text-neutralColour`
                : 'cursor-pointer bg-neutralColour text-tertiaryColour'
            } shrink-0 whitespace-nowrap rounded-lg py-[18px] text-subHeading font-medium shadow-standard`}
            onClick={() => handleClick(index)}
            style={{ width: tabWidth || 503 }}
            disabled={false}
          >
            <span>{label}</span>
          </button>
        </div>
      ))}
    </HorizontalScrollContainer>
  )
}

export default GalleryTab
