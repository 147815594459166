import React from 'react'

import BlockCard from '@src/components/block-card'

interface ComponentPropsInterface {
  blocks: Array<{ value: string; label: string }>
  handleClick: (arg: string) => void
}

function BlockList({ blocks, handleClick }: ComponentPropsInterface) {
  if (blocks.length < 1)
    return (
      <div className="flex w-full items-center justify-between rounded-lg bg-secondaryColour px-[30px] py-9 text-heading font-medium text-mainColour">
        No block found
      </div>
    )

  return (
    <>
      {blocks.map((view) => (
        <BlockCard
          key={view.value}
          label={view.label}
          handleClick={() => handleClick(view.value)}
        />
      ))}
    </>
  )
}

export default BlockList
