import React from 'react'

import {
  ChevronSvg,
  MagnifyingGlassMinusSvg,
  MagnifyingGlassPlusSvg,
  PlaySvg,
  StopSvg,
} from '@svg/react'

export interface ControlProps {
  isLoading: boolean
  rotateCamera: boolean
  handleToggleRotateCamera: () => void
  handleZoomCamera: (arg: 'in' | 'out') => void
  handleRotateCameraBy: (arg: 'right' | 'left') => void
  handleResetButtonClick: () => void
}

function Control({
  isLoading,
  rotateCamera,
  handleToggleRotateCamera,
  handleZoomCamera,
  handleRotateCameraBy,
  handleResetButtonClick,
}: ControlProps) {
  return (
    <div className="rounded-lg bg-neutralColour p-6">
      <div className="mb-4 text-2xl font-medium text-mainColour">
        CAMERA CONTROLS
      </div>
      <div className="relative m-auto h-80 w-80 overflow-hidden rounded-full ipad-mini:h-48 ipad-mini:w-48">
        <button
          type="button"
          onClick={(e) =>
            rotateCamera ? e.preventDefault() : handleZoomCamera('in')
          }
          className={`h-36 w-36 transform rounded-bl-lg rounded-tr-lg bg-secondaryColour text-mainColour hover:bg-mainColour hover:text-secondaryColour ipad-mini:h-24 ipad-mini:w-24 ${
            rotateCamera
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-mainColour'
          } absolute -top-8 left-1/2 -translate-x-1/2 rotate-45 ipad-mini:-top-6`}
          disabled={isLoading}
        >
          <span className="flex -rotate-45 transform justify-center">
            <MagnifyingGlassPlusSvg className="h-10 w-10" />
          </span>
        </button>
        <button
          type="button"
          onClick={(e) =>
            rotateCamera ? e.preventDefault() : handleZoomCamera('out')
          }
          className={`h-36 w-36 transform rounded-bl-lg rounded-tr-lg bg-secondaryColour text-mainColour hover:bg-mainColour hover:text-secondaryColour ipad-mini:h-24 ipad-mini:w-24 ${
            rotateCamera
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-mainColour'
          } absolute -bottom-8 left-1/2 -translate-x-1/2 rotate-45 ipad-mini:-bottom-6`}
          disabled={isLoading}
        >
          <span className="flex -rotate-45 transform justify-center">
            <MagnifyingGlassMinusSvg className="h-10 w-10" />
          </span>
        </button>
        <button
          type="button"
          onClick={(e) =>
            rotateCamera ? e.preventDefault() : handleRotateCameraBy('right')
          }
          className={`h-40 w-40 transform rounded-l-lg rounded-r-lg bg-secondaryColour text-mainColour hover:bg-mainColour hover:text-secondaryColour ipad-mini:h-24 ipad-mini:w-24 ${
            rotateCamera
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-mainColour'
          } absolute -right-8 top-1/2 -translate-y-1/2 rotate-45 ipad-mini:-right-6`}
          disabled={isLoading}
        >
          <span className="flex -rotate-45 transform justify-center">
            <ChevronSvg
              rotate="right"
              className="ipad-p:h-12 ipad-p:w-12 h-12 w-12"
              stroke={2}
            />
          </span>
        </button>
        <button
          type="button"
          onClick={(e) =>
            rotateCamera ? e.preventDefault() : handleRotateCameraBy('left')
          }
          className={`h-40 w-40 transform rounded-l-lg rounded-r-lg bg-secondaryColour text-mainColour hover:bg-mainColour hover:text-secondaryColour ipad-mini:h-24 ipad-mini:w-24 ${
            rotateCamera
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-mainColour'
          } absolute -left-8 top-1/2 -translate-y-1/2 rotate-45 ipad-mini:-left-6`}
          disabled={isLoading}
        >
          <span className="flex -rotate-45 transform justify-center">
            <ChevronSvg
              rotate="left"
              className="ipad-p:h-12 ipad-p:w-12 h-12 w-12"
              stroke={2}
            />
          </span>
        </button>
        <div className="absolute left-1/2 top-1/2 flex h-40 w-40 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-neutralColour p-2 ipad-mini:h-24 ipad-mini:w-24">
          <button
            className="h-full w-full rounded-full bg-secondaryColour text-2xl font-medium text-mainColour hover:bg-mainColour hover:text-secondaryColour ipad-mini:text-xl"
            type="button"
            onClick={handleResetButtonClick}
            disabled={isLoading}
          >
            RESET
          </button>
        </div>
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="inline-flex w-full items-center justify-center rounded-lg bg-secondaryColour px-6 py-4 text-mainColour drop-shadow-xl"
          onClick={handleToggleRotateCamera}
          disabled={isLoading}
        >
          <span className="mr-2 font-medium">Auto-orbit</span>{' '}
          {rotateCamera ? (
            <StopSvg className="h-5 w-5" />
          ) : (
            <PlaySvg className="h-3 w-3" />
          )}
        </button>
      </div>
    </div>
  )
}

export default Control
