import React from 'react'
import { connect } from 'react-redux'

import { ExtendedUnitFilterOptions, RootStateTypeExtra } from '@store/types'

import { Modal } from '@components/modals'
import UnitCard from '@components/unit-card'

import filterUnit from '@adUtilities/filter-unit'
import { Unit } from '@adUtilities/types/apartment'

export interface ListingProps {
  units: Array<Unit>
  showPrice: boolean
  hideSoldPrice: boolean
  availableStatusLabel: string
  trigger?: (unit: Unit) => void
  filter?: ExtendedUnitFilterOptions
  hideUnitStatus?: boolean
  disableSoldUnit?: boolean
  className?: string
}

function UnitListing({
  units,
  showPrice,
  hideSoldPrice,
  availableStatusLabel,
  trigger,
  filter,
  hideUnitStatus,
  disableSoldUnit,
  className,
}: ListingProps): React.ReactElement {
  const [filteredUnits, setFilteredUnits] = React.useState<Array<Unit>>([])
  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')

  const handleFilter = (unit: Unit) => {
    if (!filter?.apply) {
      return true
    }
    return filterUnit(unit, filter, showPrice, availableStatusLabel)
  }

  const setShortlistErrorMessage = (message: string) => {
    setAlertModalMessage(message)
    toggleAlertModal(true)
  }

  React.useEffect(
    () => setFilteredUnits(units.filter((unit) => handleFilter(unit))),
    [units, filter]
  )

  return (
    <>
      <div className={`grid grid-cols-2 gap-[15px] ${className}`}>
        {filteredUnits.length > 0 ? (
          filteredUnits.map((unit: Unit) => (
            <UnitCard
              key={unit.id}
              unit={unit}
              showPrice={showPrice}
              hideSoldPrice={hideSoldPrice}
              trigger={trigger}
              setShortlistErrorMessage={setShortlistErrorMessage}
              hideUnitStatus={hideUnitStatus}
              disableSoldUnit={disableSoldUnit}
              availableStatusLabel={availableStatusLabel}
            />
          ))
        ) : (
          <div className="w-full bg-secondaryColour p-12 text-center text-heading text-mainColour text-white">
            No Units Found
          </div>
        )}
      </div>
      <Modal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        modalWidth="max-w-lg"
        title="Message"
      />
    </>
  )
}

export default connect(
  ({
    projectIdentity: { showPrice, hideSoldPrice, statusLabels },
  }: RootStateTypeExtra) => ({
    showPrice,
    hideSoldPrice,
    availableStatusLabel: statusLabels?.available,
  })
)(UnitListing)
