import React from 'react'

import { ShortlistState } from '@store/actionSlices/shortlist'
import { ShortlistPropertyType } from '@store/types'

import { LoadingSvg } from '@components/adgroup-svg/react'
import PhoneInput from '@components/phone-input/phone-input'

import {
  SaveShortlistParams,
  useRegisterBuyerMutation,
  useSaveShortlistMutation,
} from '@api/shortlisted'

import { notifyError } from '@utilities/adgroup-utilities/notifier'

interface Buyer {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
}

const INITIAL_FORM_VALUE: Buyer = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

interface ManualBuyerRegistrationProps {
  className?: string
  projectName: string
  shortlistData: ShortlistState
  agentId?: string
  documentIds?: string[]
  onScanQRCode: () => void
}

function ManualBuyerRegistration({
  className,
  projectName,
  shortlistData,
  agentId,
  documentIds,
  onScanQRCode,
}: ManualBuyerRegistrationProps) {
  const [formValue, setFormValue] = React.useState<Buyer>(INITIAL_FORM_VALUE)
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [registeredBuyer, setRegisteredBuyer] = React.useState<
    Buyer & { isLinked: boolean }
  >()

  const [saveShortlistApi, { isLoading: isSavingShortlist }] =
    useSaveShortlistMutation()

  const [registerBuyerApi, { isLoading: isRegisteringBuyer }] =
    useRegisterBuyerMutation()

  const isProcessing = isSavingShortlist || isRegisteringBuyer

  const handleInput: (
    index: keyof Buyer
  ) => React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (index) => (e) => {
      setFormValue((prevVal) => ({
        ...prevVal,
        [index]: e.target.value,
      }))
    },
    []
  )

  const handlePhoneInput = React.useCallback((value: string) => {
    setFormValue((prevVal) => ({
      ...prevVal,
      phoneNumber: value,
    }))
  }, [])

  const validateForm = React.useCallback(() => {
    if (!isValidPhoneNumber) {
      return false
    }
    if (!formValue) {
      setError('Form cannot be empty')
      return false
    }
    return true
  }, [formValue, isValidPhoneNumber])

  const saveShortlistData = React.useCallback(async () => {
    const shortlistedProperties = shortlistData.shortlists.map<
      SaveShortlistParams['shortlistedProperties'][number]
    >((item) => ({
      propertyId: item.propertyId,
      archived: false,
      configuration:
        item.type === ShortlistPropertyType.Lot
          ? {
              packageId: item.configuration?.floorplan || undefined,
              floorPlanId: item.configuration?.floorplanOption || undefined,
              facadeId: item.configuration?.facade || undefined,
              facadeColorId: item.configuration?.facadeColor || undefined,
              internalThemeId: item.configuration?.internalTheme || undefined,
              internalColorSchemeId:
                item.configuration?.internalColorScheme || undefined,
            }
          : undefined,
    }))
    try {
      const buyerResponse = await registerBuyerApi({
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        phoneNumber: formValue.phoneNumber,
      }).unwrap()

      if (
        !buyerResponse.success ||
        !buyerResponse.data?._id ||
        !buyerResponse.data?.email
      ) {
        throw new Error(buyerResponse.message)
      }
      const saveResponse = await saveShortlistApi({
        project: projectName,
        shortlistedProperties,
        agentId,
        documentIds,
        user: buyerResponse.data?._id,
      }).unwrap()
      if (!saveResponse.success) {
        throw new Error(saveResponse.message)
      }
      setRegisteredBuyer({
        ...formValue,
        isLinked: buyerResponse.data.email !== formValue.email,
      })
    } catch (error) {
      notifyError('Failed to submit form')
      console.log(error)
    }
  }, [shortlistData, projectName, formValue, agentId, documentIds])

  const handleSubmit: React.FormEventHandler = React.useCallback(
    (e) => {
      e.preventDefault()
      if (!validateForm()) {
        return
      }
      saveShortlistData()
    },
    [formValue, validateForm, saveShortlistData]
  )

  const reset = React.useCallback(() => {
    setRegisteredBuyer(undefined)
    setFormValue(INITIAL_FORM_VALUE)
    setHasClickedSubmit(false)
  }, [])

  return (
    <div className={className}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-5">
        <div className="flex flex-col gap-4">
          <div className="text-left">
            <h4 className="mb-3 text-default font-bold leading-none">
              BUYER NAME
            </h4>
            <div className="flex gap-3">
              <input
                className="my-2 w-full rounded-lg border-gray-200 px-6 py-3 text-xl focus:border-mainColour focus:ring-0"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formValue.firstName}
                onChange={handleInput('firstName')}
                required
              />
              <input
                className="my-2 w-full rounded-lg border-gray-200 px-6 py-3 text-xl focus:border-mainColour focus:ring-0"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formValue.lastName}
                onChange={handleInput('lastName')}
                required
              />
            </div>
          </div>
          <div className="text-left">
            <h4 className="mb-5 text-default font-bold leading-none">
              BUYER PHONE
            </h4>
            <PhoneInput
              className="my-2"
              inputClassName="my-2 w-full !py-3 !rounded-r-lg border-gray-200 focus:border-mainColour !px-6 !h-auto focus:ring-0 !placeholder:text-xl !placeholder:text-grayColour !text-xl !text-mainColour"
              countrySelectorStyleProps={{
                buttonClassName:
                  '!px-2 !py-3 border-r-0 hover:mr-0 !mr-0 !rounded-l-lg h-full bg-white',
              }}
              value={formValue.phoneNumber}
              onChange={handlePhoneInput}
              onValidityChange={(status) => setIsValidPhoneNumber(status)}
              showInputError={hasClickedSubmit}
              required
            />
          </div>
          <div className="text-left">
            <h4 className="mb-3 text-default font-bold leading-none">
              BUYER EMAIL
            </h4>
            <input
              className="mt-2 w-full rounded-lg border-gray-200 px-6 py-3 text-xl focus:border-mainColour focus:ring-0"
              type="email"
              name="email"
              placeholder="john.doe@gmail.com"
              value={formValue.email}
              onChange={handleInput('email')}
              required
            />
          </div>
        </div>
        {error ? (
          <div>
            <p>{error}</p>
          </div>
        ) : null}
        {registeredBuyer ? null : (
          <button
            type="submit"
            className="flex-1 rounded-lg bg-gradient px-4 py-6 text-3xl font-medium text-white shadow-standard"
            disabled={isProcessing}
            onClick={() => setHasClickedSubmit(true)}
          >
            <div className="flex items-center justify-center gap-2">
              {isProcessing && (
                <LoadingSvg className="h-5 w-5 fill-white text-black" />
              )}
              <span className="text-[32px]">Email Shortlist</span>
            </div>
          </button>
        )}
      </form>
      {registeredBuyer ? (
        <div className="mt-3 flex flex-col gap-1">
          <p className="font-medium">
            {registeredBuyer.isLinked
              ? 'Your shortlist has been sent.'
              : `Your shortlist has been sent to ${registeredBuyer.email}`}
          </p>
          <button type="button" onClick={reset}>
            <p className="text-left text-base font-normal underline">
              Register another buyer manually
            </p>
          </button>
        </div>
      ) : (
        <div className="flex w-full justify-center">
          <button
            type="button"
            className="mt-3 px-2 font-medium underline"
            onClick={onScanQRCode}
          >
            Scan QR code instead
          </button>
        </div>
      )}
    </div>
  )
}

export default ManualBuyerRegistration
