import React from 'react'

import { GalleryItemInterface, PlayerState } from '@store/types'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'
import useImageURLValidator from '@adUtilities/hooks/image-url-validator'

import PlayStopButton from './play-stop-button'

interface ComponentPropsInterface {
  item: GalleryItemInterface
  isActive: boolean
  playerState?: PlayerState
  onItemClick: (event: React.MouseEvent<HTMLDivElement>) => void
  onPlayStopClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    action: PlayerState
  ) => void
}

const fallbackImageURL = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

function GalleryItem({
  item,
  isActive,
  playerState,
  onItemClick,
  onPlayStopClick,
}: ComponentPropsInterface) {
  const { validatedUrl, isLoading } = useImageURLValidator(item.imageSource)

  const validatedImageURL = React.useMemo(() => {
    if (isLoading || !validatedUrl) {
      return ''
    }

    return AssetHandler({
      url: validatedUrl,
      type: item.assetType || 'legacy',
      noSpliceUrl: item.config?.noSpliceUrl || false,
    })
  }, [validatedUrl, isLoading])

  return (
    <div
      className={`rounded-lg shadow-standard ${
        isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${isActive ? 'bg-gradient' : 'bg-secondaryColour'}`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (isLoading) {
          e.preventDefault()
        } else {
          onItemClick(e)
        }
      }}
      role="none"
    >
      <div className="rounded-lg">
        <div className="relative flex h-48 items-center justify-center">
          {isLoading ? (
            <div className="h-full w-full rounded-lg bg-slate-700/30 drop-shadow-lg">
              <div className="shimmer-container relative h-full !rounded-lg opacity-50"></div>
            </div>
          ) : (
            <>
              <img
                className="h-full w-full rounded-lg object-cover"
                role="none"
                loading="lazy"
                src={validatedImageURL || fallbackImageURL}
                alt={item.title}
              />
              {item.videoSource && onPlayStopClick && (
                <div className="absolute inset-0 z-1 flex items-center justify-center">
                  <PlayStopButton
                    isActive={isActive}
                    playerState={playerState}
                    onPlayStopClick={onPlayStopClick}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div
        title={item.title || 'Untitled'}
        className={`truncate p-4 text-default ${
          isActive ? 'text-neutralColour' : 'text-mainColour'
        }`}
      >
        {item.title || 'Untitled'}
      </div>
    </div>
  )
}

export default GalleryItem
