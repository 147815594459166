import React, { ComponentProps } from 'react'

import Container from '@components/container'
import { Modal } from '@components/modals'

import { CloseCircleSvg } from '@svg/react'

import SessionSettings from './session-settings'

type ButtonProps = ComponentProps<'button'>

interface SessionSettingsModalActionProps extends ButtonProps {
  onTrigger?: () => void
}

const SessionSettingsModalAction = React.forwardRef<
  HTMLButtonElement,
  SessionSettingsModalActionProps
>(({ children, className = '', style, disabled, tabIndex, onTrigger }, ref) => {
  const [show, setShow] = React.useState(false)

  const handleClick = React.useCallback(() => {
    setShow(true)
    onTrigger?.()
  }, [onTrigger])

  const handleClose = React.useCallback(() => {
    setShow(false)
  }, [])

  return (
    <>
      <button
        ref={ref}
        type="button"
        className={className}
        style={style}
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={handleClick}
      >
        {children}
      </button>
      <Modal
        isVisible={show}
        toggleModal={setShow}
        modalWidth="!w-full"
        theme={{
          textColor: '',
          mainBg: 'bg-gradient',
          secondaryBg: '',
          containerBorder: 'rounded-none',
          containerPadding: 'p-0',
        }}
        noMainControls
      >
        <div id="sessionSettingsModalActionRoot" className="h-screen w-full">
          <Container
            className="flex !h-full w-full flex-col pb-8 pt-14"
            rootElementId="sessionSettingsModalActionRoot"
          >
            <SessionSettings className="m-auto h-full w-5/6 lg:w-4/6" />
            <button
              className="absolute right-5 top-5"
              type="button"
              onClick={handleClose}
            >
              <CloseCircleSvg className="m-2 fill-neutralColour" />
            </button>
          </Container>
        </div>
      </Modal>
    </>
  )
})

export default SessionSettingsModalAction
