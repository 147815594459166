import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import { isOffline } from '@utilities/offline-handler-util'

import Disconnect from './disconnect'
import Refresh from './refresh'

interface PagePropsInterface {
  className?: string
  projectIdentity: ProjectIdentity
}

function Tools({ className = '', projectIdentity }: PagePropsInterface) {
  return (
    <div className={`p-5 ${className}`}>
      <div className="mb-4 flex w-full items-center justify-center py-2 text-title font-medium text-neutralColour">
        Tools
      </div>
      <div className="no-scrollbar w-full overflow-auto">
        <div className="grid grid-rows-2 gap-4">
          {projectIdentity.showClearCacheOption && !isOffline() && (
            <div>
              <Refresh />
            </div>
          )}
          {projectIdentity.disconnect && (
            <div>
              <Disconnect projectIdentity={projectIdentity} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Tools)
