function numberWithCommas(num: string | number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}
}

const getFirebaseHost = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return 'localhost'
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebaseHost || 'localhost'
}

const getFirebasePort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebasePort || ''
}

const getAssetHost = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return 'localhost'
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.assetHost || 'localhost'
}

const getAssetPort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.assetPort || ''
}

const getActiveProjectId = (): string => {
  const projectIdentity = localStorage.getItem('projectIdentity')
  if (!projectIdentity) {
    return ''
  }
  const projectIdentityObj = JSON.parse(projectIdentity)
  return projectIdentityObj.projectId || ''
}

const roomQueryParams = () => {
  const appConfig = localStorage.getItem('appConfig')
  if (!appConfig) return {}

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.room ? { showcaseRoom: appConfigObject.room } : {}
}

// TODO: Remove this roomQueryString function and replace it with roomQueryParams in the implementation
const roomQueryString = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) return ''

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.room ? `?showcaseRoom=${appConfigObject.room}` : ''
}

const getFileNameFromPathOrURL = (str: string) => {
  const fileName = str.split('/').pop()?.split('.')
  if (fileName) {
    fileName.pop()
    return fileName.join('.')
  }
  return ''
}

const isValidSessionId = (value: string): boolean =>
  !Number.isNaN(value) && /^\d{6}$/.test(value)

const capitalizeFirstLetter = (string: string) => {
  const text = string.toLowerCase()
  return text.charAt(0).toUpperCase() + text.slice(1)
}

// TODO: should be removed in https://ad-group.atlassian.net/browse/ENG-5485
const isNotSessionSettingsOrTools = (pathname: string): boolean => !!pathname

const formatPrice = (value: string | number) =>
  !Number.isNaN(Number(value))
    ? `$${Number(value).toLocaleString('en-US')}`
    : ''

const areObjectsDeepEqual = (object1: any, object2: any): boolean => {
  if (object1 === object2) {
    return true
  }

  if (
    typeof object1 !== 'object' ||
    typeof object2 !== 'object' ||
    object1 === null ||
    object2 === null
  ) {
    return false
  }

  const object1Keys = Object.keys(object1)
  const object2Keys = Object.keys(object2)

  if (object1Keys.length !== object2Keys.length) {
    return false
  }

  return object1Keys.every(
    (key) =>
      object2Keys.includes(key) &&
      areObjectsDeepEqual(object1[key], object2[key])
  )
}

const generateId = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export {
  roomQueryString,
  roomQueryParams,
  numberWithCommas,
  getQueryStringParams,
  getFirebaseHost,
  getFirebasePort,
  getAssetHost,
  getAssetPort,
  getFileNameFromPathOrURL,
  isValidSessionId,
  getActiveProjectId,
  capitalizeFirstLetter,
  isNotSessionSettingsOrTools,
  formatPrice,
  areObjectsDeepEqual,
  generateId,
}
