import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  GalleryItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryItem from '@components/gallery-item'
import GallerySkeleton from '@components/gallery-skeleton'
import GalleryTab from '@components/gallery-tab'

import {
  selectFromResult as selectFromGalleryResult,
  useGetGalleryQuery,
} from '@api/gallery'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { PlaySvg, StopSvg } from '@svg/react'

interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Gallery({ session, projectIdentity }: PagePropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [slideShowState, setSlideShowState] = React.useState(false)
  const [transitionEffectClass, setTransitionEffectClass] = React.useState('')

  const galleryPayload = useGetGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromGalleryResult }
  )

  const tabItems: Array<string> = React.useMemo(() => {
    const { galleries = [] } = galleryPayload

    if (galleries.length < 1) {
      return []
    }

    return galleries.map((item) => item.label || '')
  }, [galleryPayload])

  const galleryItems: Array<GalleryItemInterface> = React.useMemo(() => {
    const { galleries = [] } = galleryPayload

    if (galleries.length < 1) {
      return []
    }

    return galleries[activeTabIndex].items || []
  }, [galleryPayload, activeTabIndex])

  const handleOnTabChange = React.useCallback(
    async (index: number) => {
      setTransitionEffectClass(
        index > activeTabIndex
          ? 'transition-slide-left'
          : 'transition-slide-right'
      )
      setActiveTabIndex(index)
      setActiveSlideIndex(0)
      await firebaseControlQuery.update({
        [`design.galleryControlV2.activeTabIndex`]: index,
        [`design.galleryControlV2.activeSlideIndex`]: 0,
      })
    },
    [activeTabIndex]
  )

  const handleOnItemClick = React.useCallback(
    async (event: MouseEvent<HTMLDivElement>, index: number) => {
      event.preventDefault()
      setActiveSlideIndex(index)
      await firebaseControlQuery.update({
        [`design.galleryControlV2.activeSlideIndex`]: index,
      })
    },
    []
  )

  const handleOnSlideShowClick = React.useCallback(async () => {
    setSlideShowState(!slideShowState)
    await firebaseControlQuery.update({
      [`design.galleryControlV2.autoPlayState`]: !slideShowState,
    })
  }, [slideShowState])

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      design: {
        galleryControlV2: {
          activeTabIndex: activeTabIndexFirebase,
          activeSlideIndex: activeSlideIndexFirebase,
          autoPlayState: autoPlayStateFirebase,
        },
      },
    } = session

    setActiveTabIndex(activeTabIndexFirebase)
    setActiveSlideIndex(activeSlideIndexFirebase)
    setSlideShowState(autoPlayStateFirebase)
  }, [session?.design.galleryControlV2])

  return (
    <Container>
      <DataHandler
        payload={{ ...galleryPayload, data: galleryPayload.galleries }}
        skeletonFrame={<GallerySkeleton hasTabBar />}
      >
        <div className="h-full w-full">
          <div className="relative flex h-top-bar w-full items-center justify-center px-5 text-neutralColour">
            <div className="text-title font-medium">Gallery</div>
            {galleryItems.length > 1 && (
              <div className="absolute bottom-14 right-5">
                <button
                  type="button"
                  disabled={false}
                  onClick={handleOnSlideShowClick}
                  className="inline-flex cursor-pointer items-center gap-2.5 text-default opacity-100"
                >
                  <span>{slideShowState ? 'Stop' : 'Start'} slideshow</span>{' '}
                  {slideShowState ? <StopSvg /> : <PlaySvg />}
                </button>
              </div>
            )}
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <GalleryTab
              items={tabItems}
              activeGalleryTabIndex={activeTabIndex}
              onTabChange={handleOnTabChange}
              className="mb-[15px] flex-shrink-0"
            />
            <TransitionGroup className="h-full w-full">
              <CSSTransition
                key={activeTabIndex}
                classNames={transitionEffectClass}
                timeout={500}
                exit={false}
                onEntered={(node: HTMLElement) =>
                  node.classList.remove('transition-slide-left-enter-done')
                }
              >
                <div className="grid grid-cols-3 gap-4 px-4">
                  {galleryItems.map((item, index) => (
                    <GalleryItem
                      key={index as number}
                      item={item}
                      isActive={index === activeSlideIndex}
                      onItemClick={(event: MouseEvent<HTMLDivElement>) =>
                        handleOnItemClick(event, index)
                      }
                    />
                  ))}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity,
}: RootStateTypeExtra) => ({
  session,
  projectIdentity,
})

export default connect(mapStateToProps)(Gallery)
