import React from 'react'
import { connect } from 'react-redux'

import type {
  BedConfigInterface,
  HouseAndLandConfigurationInterface,
  LotFilterConfiguration,
  LotFilterInterface,
  PriceRange,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import ToggleSwitch from '@components/toggle-switch'
import VerticalScrollContainer from '@components/vertical-scroll-container'

import {
  PrecinctListItemInterface,
  SummaryCollectionInterface,
} from '@api/houseAndLand'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { CloseSvg } from '@svg/react'

import { MinMaxInterface } from '@adUtilities/types/common'
import { FilterOptionsConfigurations } from '@adUtilities/types/houseAndLand'

import DropDown from '../dropdown'
import { CheckboxInput } from '../form-controls'
import FILTER_INITIAL_STATE from './filterStateHouseAndLand'
import RangeOptions from './range-options'

interface FilterProps {
  isOpen: boolean
  toggle: (arg: boolean) => void
  activePrecinct: string
  setActivePrecinct: (arg: string) => void
  firebaseLotFilter: LotFilterInterface
  prices: PriceRange
  showPrice: boolean
  hideStages?: boolean
  isStageFetching?: boolean
  aspects: Array<string>
  precinctList: Array<PrecinctListItemInterface>
  packageSummary: SummaryCollectionInterface | null
  bedConfig: Array<BedConfigInterface>
  houseAndLandConfig: HouseAndLandConfigurationInterface
  projectIdentity: ProjectIdentity
  firebaseActiveStage: string
}
interface FilterPayload {
  [key: string]: string | boolean
}
function HouseAndLandFilter({
  isOpen,
  toggle,
  activePrecinct,
  setActivePrecinct,
  firebaseLotFilter,
  prices,
  showPrice,
  aspects,
  bedConfig,
  houseAndLandConfig,
  projectIdentity,
  firebaseActiveStage,
  hideStages,
  isStageFetching,
  precinctList,
  packageSummary,
}: FilterProps) {
  const filterRef = React.useRef<HTMLDivElement>(null)
  const {
    lotFrontage: lotFrontageOptions,
    lotArea: lotAreaOptions,
    storey: storeyOptions,
    builder: builderOptions,
  } = houseAndLandConfig || {}
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const activePackageSummary = packageSummary?.[activePrecinct]
  const [activeStage, setActiveStage] = React.useState(firebaseActiveStage)
  const [checkAnyStage, setAnyStage] = React.useState(
    firebaseLotFilter.anyStage
  )
  const [isStageChange, setStageChange] = React.useState(false)

  const [configurations, setConfigurations] =
    React.useState<FilterOptionsConfigurations>(
      firebaseLotFilter.configurations
    )
  const [lotConfigurations, setLotConfigurations] =
    React.useState<LotFilterConfiguration>(firebaseLotFilter.lotConfiguration)
  const [builder, setBuilder] = React.useState<Array<string>>(
    firebaseLotFilter.builder
  )
  const [storey, setStorey] = React.useState<Array<number>>(
    firebaseLotFilter.storey
  )
  const [showAvailable, setShowAvailable] = React.useState(
    firebaseLotFilter.showOnlyAvailable
  )

  const precinctOptions = React.useMemo(
    () =>
      precinctList.map(({ label }) => ({
        label,
        value: label,
      })),
    [precinctList]
  )

  const stageOptions = React.useMemo(
    () =>
      Object.keys(activePackageSummary?.stages ?? {})?.map((label) => ({
        label: `Stage ${label}`,
        value: label,
      })),
    [activePrecinct, activePackageSummary]
  )

  const handleLotConfigurationChange = (configs: LotFilterConfiguration) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.lotConfiguration`]: configs,
    })

  const handleConfigurationChange = (configs: FilterOptionsConfigurations) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.configurations`]: configs,
    })

  const handleAnyStage = (checked: boolean) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.anyStage`]: checked,
    })

  const handleAspectChange = (value: string) => {
    const lotAspect: string[] = lotConfigurations?.aspects
    if (lotAspect) {
      let filterAspect: string[] = []
      if (lotAspect.includes(value)) {
        filterAspect = lotAspect.filter((item) => item !== value)
      } else {
        filterAspect = [...lotAspect, value]
      }
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.lotConfiguration.aspects`]: filterAspect,
      })
    }
  }

  const handleStoreyChange = (value: number) => {
    if (storey.includes(value)) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.storey`]: storey.filter(
          (item) => item !== value
        ),
      })
    } else {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.storey`]: [...storey, value],
      })
    }
  }

  const handleBuilderChange = (value: string) => {
    if (builder.includes(value)) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.builder`]: builder.filter(
          (item) => item !== value
        ),
      })
    } else {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.builder`]: [...builder, value],
      })
    }
  }

  const handleShowAvailable = (checked: boolean) =>
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter.showOnlyAvailable`]: checked,
    })

  const applyFilter = () => {
    const filterPayload: FilterPayload = {
      [`houseAndLand.lotFilter.apply`]: true,
    }
    if (precinctOptions.length > 1) {
      filterPayload['houseAndLand.activePrecinctId'] = activePrecinct
    }
    if (!hideStages && stageOptions.length > 1) {
      filterPayload['houseAndLand.activeStageId'] = activeStage
    }
    firebaseControlQuery.update(filterPayload)
    toggle(false)
  }

  const resetFilter = async () => {
    firebaseControlQuery.update({
      [`houseAndLand.lotFilter`]: FILTER_INITIAL_STATE,
    })
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node) &&
      isOpen
    ) {
      toggle(false)
    }
  }

  React.useEffect(() => {
    if (isStageChange) {
      const firstStage =
        Object.keys(activePackageSummary?.stages ?? {})?.[0] || ''
      setActiveStage(firstStage)
    }
  }, [activePackageSummary, activePrecinct])

  React.useEffect(() => {
    setConfigurations(firebaseLotFilter.configurations)
    setLotConfigurations(firebaseLotFilter.lotConfiguration)
    setBuilder(firebaseLotFilter.builder)
    setStorey(firebaseLotFilter.storey)
    setShowAvailable(firebaseLotFilter.showOnlyAvailable)
    setAnyStage(firebaseLotFilter.anyStage)
  }, [firebaseLotFilter])

  React.useEffect(() => {
    if (isOpen && firebaseLotFilter?.apply) {
      firebaseControlQuery.update({
        [`houseAndLand.lotFilter.apply`]: false,
      })
    }
  }, [firebaseLotFilter, activeStage, activePrecinct])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <div
      ref={filterRef}
      className={`fixed inset-y-0 right-0 z-50 h-screen w-[20rem] rounded-bl-lg rounded-tl-lg bg-secondaryColour p-5 shadow-standard ipad-pro:w-[25rem] ipad-mini-land:w-[25rem] ${
        isOpen ? '' : 'translate-x-full'
      } transition-transform duration-500`}
    >
      <div className="flex h-screen flex-col text-mainColour">
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <h2 className="text-title font-medium">Filters</h2>
            <button
              className="cursor-pointer"
              type="button"
              onClick={() => {
                toggle(false)
              }}
            >
              <CloseSvg className="mr-2 h-4 w-4" strokeColor="currentColor" />
            </button>
          </div>
          <button
            className="text-xl font-normal text-[#808080] underline opacity-60"
            type="button"
            onClick={resetFilter}
          >
            Reset filters
          </button>
        </div>
        <VerticalScrollContainer className="mt-5 flex-1 overflow-y-auto">
          <div className="flex flex-col gap-5">
            {precinctOptions.length > 1 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Precinct
                </span>
                <DropDown
                  onSelect={(value) => {
                    setActivePrecinct(value)
                    setStageChange(true)
                  }}
                  items={precinctOptions}
                  value={activePrecinct}
                />
              </div>
            )}

            {!hideStages && isStageFetching && (
              <div className="flex flex-col gap-1">
                <div className="h-8 w-20 rounded-lg bg-slate-700/30">
                  <div className="shimmer-container relative !p-2 opacity-50"></div>
                </div>
                <div className="h-10 w-full rounded-lg bg-slate-700/30">
                  <div className="shimmer-container relative opacity-50"></div>
                </div>
                <div className="mt-2 h-4 w-32 rounded-lg bg-slate-700/30">
                  <div className="shimmer-container relative !p-2 opacity-50"></div>
                </div>
              </div>
            )}

            {stageOptions?.length > 1 && !hideStages && !isStageFetching && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Stage
                </span>
                <DropDown
                  onSelect={(value) => {
                    setActiveStage(value)
                  }}
                  disabled={checkAnyStage}
                  items={stageOptions}
                  value={activeStage}
                  className={checkAnyStage ? 'opacity-60' : ''}
                />
                <div className="inline-flex items-center gap-3">
                  <ToggleSwitch
                    key="offlineToggleSwitch"
                    id="offlineToggleSwitch"
                    onChangeCallback={(e) => {
                      handleAnyStage(e.target.checked)
                    }}
                    initialState={checkAnyStage}
                    onColor="bg-mainColour"
                    offColor="bg-grayColour"
                  />
                  <span className="text-xl text-mainColour">Any Stage</span>
                </div>
              </div>
            )}

            {showPrice && (
              <RangeOptions
                field="price"
                options={prices}
                range={lotConfigurations.price}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    price: configValue,
                  })
                }}
              />
            )}

            {Object.keys(bedConfig).length > 0 && (
              <span className="text-default font-medium uppercase">
                Configuration
              </span>
            )}

            {Object.entries(bedConfig)?.map(([field, value]) => {
              const range =
                configurations[field as keyof FilterOptionsConfigurations]
              return (
                range && (
                  <RangeOptions
                    field={field}
                    key={field}
                    options={value as unknown as Array<string | number>}
                    range={
                      configurations?.[
                        field as keyof FilterOptionsConfigurations
                      ] || {
                        min: 'Any',
                        max: 'Any',
                      }
                    }
                    handleChange={(configValue: MinMaxInterface) => {
                      handleConfigurationChange({
                        ...configurations,
                        [field as keyof FilterOptionsConfigurations]:
                          configValue,
                      })
                    }}
                  />
                )
              )
            })}

            {(lotFrontageOptions?.length || 0) > 0 && (
              <RangeOptions
                field="lotFrontage"
                options={lotFrontageOptions || []}
                range={lotConfigurations.frontage}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    frontage: configValue,
                  })
                }}
              />
            )}

            {(lotAreaOptions?.length || 0) > 0 && (
              <RangeOptions
                field="lotArea"
                options={lotAreaOptions || []}
                range={lotConfigurations.area}
                handleChange={(configValue: MinMaxInterface) => {
                  handleLotConfigurationChange({
                    ...lotConfigurations,
                    area: configValue,
                  })
                }}
              />
            )}

            {(storeyOptions?.length || 0) > 0 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Storey
                </span>
                <div className="grid grid-cols-1 gap-2.5">
                  {storeyOptions?.map(({ label, value }) => (
                    <button
                      type="button"
                      key={label}
                      onClick={() => {
                        handleStoreyChange(value)
                      }}
                      className={`rounded-lg py-2 text-center text-default font-medium ${
                        storey?.includes(value)
                          ? 'bg-gradient text-white'
                          : 'bg-neutralColour'
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {aspects?.length > 0 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Aspect
                </span>
                <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(90px,1fr))]">
                  {aspects?.map((aspectValue: string) => (
                    <button
                      type="button"
                      key={aspectValue}
                      onClick={() => {
                        handleAspectChange(aspectValue.toLowerCase())
                      }}
                      className={`truncate rounded-lg py-2 text-center text-default font-medium ${
                        lotConfigurations?.aspects?.includes(
                          aspectValue.toLowerCase()
                        )
                          ? 'bg-gradient text-white'
                          : 'bg-neutralColour'
                      }`}
                    >
                      {aspectValue}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {(builderOptions?.length || 0) > 0 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Builder
                </span>
                <div className="grid grid-cols-1 gap-2.5">
                  {builderOptions?.map((builderValue: string) => (
                    <button
                      type="button"
                      key={builderValue}
                      onClick={() => {
                        handleBuilderChange(builderValue.toLowerCase())
                      }}
                      className={`rounded-lg py-2 text-center text-default font-medium ${
                        builder?.includes(builderValue.toLowerCase())
                          ? 'bg-gradient text-white'
                          : 'bg-neutralColour'
                      }`}
                    >
                      {builderValue}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </VerticalScrollContainer>
        <div className="mb-8 mt-4">
          <div className="flex items-center gap-2">
            <CheckboxInput
              initialState={showAvailable}
              onChangeCallback={(e) => {
                handleShowAvailable(e.target.checked)
              }}
              onColor="bg-mainColour"
              offColor="bg-neutralColour"
              id="availableResidence"
            />
            <span className="text-xl">Only show available lots</span>
          </div>
          <div className="mt-2">
            <button
              type="button"
              onClick={applyFilter}
              disabled={firebaseLotFilter?.apply}
              className={`w-full rounded-lg bg-gradient p-4 text-center text-subHeading font-medium text-white shadow-standard ${
                firebaseLotFilter?.apply
                  ? 'cursor-not-allowed opacity-40'
                  : 'opacity-100'
              }`}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(
  ({
    projectIdentity: {
      prices,
      showPrice,
      bedConfig,
      houseAndLandConfig,
      aspects,
    },
    projectIdentity,
    houseAndLand: { precinctList, packageSummary },
  }: RootStateTypeExtra) => ({
    projectIdentity,
    prices,
    showPrice,
    bedConfig,
    houseAndLandConfig,
    aspects,
    precinctList,
    packageSummary,
  })
)(HouseAndLandFilter)
