import React from 'react'

import { SvgProps } from './types'

function ArrowTurnLeftSvg({
  width,
  height,
  className,
  size,
  styles,
  strokeColor,
  fill,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '31'}
      height={height || '16'}
      viewBox="0 0 31 16"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      stroke={strokeColor || 'currentColor'}
    >
      <path
        d="M6.17628 2L2 6.17628L6.17628 10.3526"
        stroke={strokeColor || 'currentColor'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.04407 6.17627H22.2289C26.0632 6.17627 29.1458 9.39527 29.1458 13.2237V14.5288"
        stroke={strokeColor || 'currentColor'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowTurnLeftSvg
