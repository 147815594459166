/* eslint-disable react/prop-types */
import React from 'react'
import { Range, getTrackBackground } from 'react-range'

import { PlayerState } from '@store/types'

import { SimplifiedThemeConfig } from '@api/config'

import { FastForwardSvg, RewindSvg } from '@svg/react'

const timeIntervalInMilliseconds = 250

interface ComponentProps {
  scrubbarTimerState: boolean
  isBuffering: boolean
  playerState: PlayerState
  videoLengthInMilliseconds: number
  currentVideoPositionInMilliseconds: number
  isSeeking: boolean
  themeConfig: SimplifiedThemeConfig
  handleSeekTo: (argInSeconds: number) => void
}

function ScrubBar({
  scrubbarTimerState,
  isBuffering,
  playerState,
  videoLengthInMilliseconds,
  currentVideoPositionInMilliseconds,
  isSeeking,
  themeConfig,
  handleSeekTo,
}: ComponentProps) {
  const videoLengthRef = React.useRef<number>(0)

  const [rangeValue, setRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const toRoundedSecond = (milliseconds: number) =>
    Math.round(milliseconds / 1000)

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  const setTimer = () => {
    if (!intervalId) {
      setIntervalId(
        setInterval(() => {
          setRangeValue((previousValue) => {
            const newValue = previousValue + timeIntervalInMilliseconds
            return newValue >= videoLengthRef.current
              ? 0
              : Math.round(newValue / 0.1) * 0.1
          })
        }, 250)
      )
    }
  }

  const handleOnChange = (valueInMilliseconds: number) => {
    resetTimer()
    setRangeValue(valueInMilliseconds)
  }

  const handleOnChangeComplete = async (valueInMilliseconds: number) => {
    await handleSeekTo(toRoundedSecond(valueInMilliseconds))
  }

  const handleFastForward = React.useCallback(async () => {
    resetTimer()
    const newValue = Math.min(rangeValue + 15 * 1000, videoLengthRef.current)
    setRangeValue(newValue)
    await handleSeekTo(toRoundedSecond(newValue))
  }, [rangeValue])

  const handleRewind = React.useCallback(async () => {
    resetTimer()
    const newValue = Math.max(rangeValue - 15 * 1000, 0)
    setRangeValue(newValue)
    await handleSeekTo(toRoundedSecond(newValue))
  }, [rangeValue])

  React.useEffect(() => {
    if (
      playerState === PlayerState.Play &&
      !isBuffering &&
      scrubbarTimerState
    ) {
      setTimer()
    } else {
      resetTimer()
    }
  }, [playerState, scrubbarTimerState, isBuffering])

  React.useEffect(() => {
    videoLengthRef.current = videoLengthInMilliseconds
    setRangeValue(0)
  }, [videoLengthInMilliseconds])

  React.useEffect(() => {
    setRangeValue(currentVideoPositionInMilliseconds)
  }, [currentVideoPositionInMilliseconds])

  React.useEffect(
    () => () => {
      resetTimer()
      setRangeValue(0)
      videoLengthRef.current = 0
    },
    []
  )

  return (
    <>
      <button
        type="button"
        onClick={(e) => {
          if (isSeeking) {
            e.preventDefault()
            return
          }
          handleRewind()
        }}
      >
        <RewindSvg className="h-6 w-6 cursor-pointer text-mainColour" />
      </button>

      <button
        type="button"
        onClick={(e) => {
          if (isSeeking) {
            e.preventDefault()
            return
          }
          handleFastForward()
        }}
      >
        <FastForwardSvg className="h-6 w-6 cursor-pointer text-mainColour" />
      </button>

      <div className="w-full px-1">
        {videoLengthInMilliseconds > 0 ? (
          <Range
            min={0}
            max={videoLengthInMilliseconds}
            step={0.1}
            values={[rangeValue]}
            onChange={(value) => handleOnChange(value[0] || 0)}
            onFinalChange={(value) => handleOnChangeComplete(value[0] || 0)}
            disabled={isSeeking}
            renderTrack={({ props, children }) => (
              <div
                role="slider"
                tabIndex={0}
                aria-valuemin={0}
                aria-valuemax={videoLengthInMilliseconds}
                aria-valuenow={rangeValue}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '30px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  ref={props.ref}
                  className="h-1 w-full rounded-lg"
                  style={{
                    background: getTrackBackground({
                      values: [rangeValue],
                      colors: [
                        themeConfig.mainColour || '',
                        themeConfig.neutralColour || '',
                      ],
                      min: 0,
                      max: videoLengthInMilliseconds,
                    }),
                    alignSelf: 'center',
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
                key={props.key}
                className="h-4 w-4 rounded-full"
                style={{
                  backgroundColor: themeConfig.mainColour || '',
                }}
              ></div>
            )}
          />
        ) : (
          <div className="rounded-lg border-b-4 border-neutralColour"></div>
        )}
      </div>
    </>
  )
}

export default ScrubBar
