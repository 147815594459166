import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
// import { connect } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { TokenInterface } from '@store/actionSlices/token'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import AbsoluteContainer from '@components/container/absolute-container'
import ErrorBoundaryFallback from '@components/error-boundary-fallback'
import Navigation from '@components/navigation'
import {
  ProtectedRoutes,
  PublicRoutes,
  SessionActiveRoutes,
} from '@components/route-guards'
import WithPageTransition from '@components/transition'

import PageNotFound from '@pages/404'
import { AreaViewApartments, AreaViewHouseAndLand } from '@pages/area-view'
import Brochure from '@pages/brochure'
import Dashboard from '@pages/dashboard'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import Gallery from '@pages/gallery'
import InteractiveMap from '@pages/interactive-map'
import Location from '@pages/location'
import Login from '@pages/login'
import LotView from '@pages/lot-view'
import Panoramic from '@pages/panoramic'
import {
  BuildingViewApartments,
  StageViewHouseAndLand,
} from '@pages/properties-view'
import Shortlist from '@pages/shortlist'
import Snaploader from '@pages/snaploader'
import Team from '@pages/team'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'
import {
  LevelViewApartments,
  PrecinctViewHouseAndLand,
} from '@pages/zones-view'

import useCurrentPage from '@utilities/location-util'
import { hasToken } from '@utilities/token-helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

interface RouteProps {
  session: SessionMap | undefined
  token: TokenInterface
  projectIdentity: ProjectIdentity
}

function Routes({ session, token, projectIdentity }: RouteProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const history = useHistory()
  const location = useLocation()
  const [currentPage] = useCurrentPage()

  const IGNORE_ROUTER_PUSHER = ['/lot-view']

  const disableNavigation = React.useMemo(
    () =>
      !hasToken(token) ||
      currentPage === '' ||
      currentPage === 'vision' ||
      currentPage === 'dashboard',
    [currentPage, token]
  )

  React.useEffect(() => {
    const activeRouteFirebase = session?.activeRoute
      ? `/${session.activeRoute}`
      : ''

    if (activeRouteFirebase) {
      if (IGNORE_ROUTER_PUSHER.includes(location.pathname)) {
        return
      }
      if (!session?.connected && activeRouteFirebase === '/vision') {
        return
      }
      if (
        location.pathname === '/shortlist' &&
        activeRouteFirebase === '/stages'
      ) {
        return
      }
      if (activeRouteFirebase !== location.pathname) {
        history.push({
          pathname: activeRouteFirebase,
          state: { from: location.pathname },
        })
      }
      if (activeRouteFirebase !== '/interactive-map') {
        firebaseControlQuery.update({
          interactiveMap: [],
        })
      }
    }
  }, [session])

  return (
    <>
      {disableNavigation ? null : <Navigation type="left-navigation" />}
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <WithPageTransition>
          <Switch location={location}>
            <SessionActiveRoutes
              exact
              path="/gallery"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Gallery />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/video-gallery"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <VideoGallery />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/external-views"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <ExternalViews />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/brochure"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Brochure />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/location"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Location />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/interactive-map"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <InteractiveMap />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/level-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <LevelViewApartments />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              exact
              path="/shortlist"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Shortlist />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/area-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <AreaViewApartments />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/area-view-house-and-land"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <AreaViewHouseAndLand />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/precinct"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <PrecinctViewHouseAndLand />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              path="/stages"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <StageViewHouseAndLand />
              </AbsoluteContainer>
            </SessionActiveRoutes>

            <SessionActiveRoutes
              exact
              path="/building"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <BuildingViewApartments />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/lot-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <LotView />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/vision"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Vision />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/teams"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Team />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              exact
              path="/panoramic"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Panoramic />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/snaploader-view"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <Snaploader />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <SessionActiveRoutes
              path="/3d-building"
              authenticationPath="/"
              returnPath="/dashboard"
            >
              <AbsoluteContainer>
                <EnvisionVR />
              </AbsoluteContainer>
            </SessionActiveRoutes>
            <ProtectedRoutes
              exact
              path="/dashboard"
              authenticationPath="/"
              redirectSessionPath="/vision"
            >
              <AbsoluteContainer>
                <Dashboard />
              </AbsoluteContainer>
            </ProtectedRoutes>
            <PublicRoutes
              exact
              path="/:projectId"
              authenticatedPath="/dashboard"
            >
              <Login />
            </PublicRoutes>
            <PublicRoutes exact path="/" authenticatedPath="/dashboard">
              <Login />
            </PublicRoutes>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </WithPageTransition>
      </ErrorBoundary>
    </>
  )
}

export default connect(
  ({ firestore: { session }, projectIdentity, token }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
  })
)(Routes)
