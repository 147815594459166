import { PlayerState, ProjectIdentity } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

interface ComponentProps {
  galleryName: 'floorGallery' | 'lotGallery' | 'unitGallery' | 'videoGallery'
  projectIdentity: ProjectIdentity
}

const VideoPlayerFirebaseService = ({
  galleryName,
  projectIdentity,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const updatePlayState = async () => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.playerState`]: PlayerState.Play,
      })
      .catch(() => console.error('Failed updatePlayState'))
  }

  const updatePauseState = async () => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.playerState`]: PlayerState.Pause,
      })
      .catch(() => console.error('Failed updatePauseState'))
  }

  const updateStopState = async () => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.playerState`]: PlayerState.Stop,
        [`${galleryName}.playerControlV2.loop`]: true,
        [`${galleryName}.playerControlV2.seekToValueInSeconds`]: null,
        [`${galleryName}.playerControlV2.videoLengthInSeconds`]: 0,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]: 0,
        [`${galleryName}.playerControlV2.videoSyncKey`]: null,
      })
      .catch(() => console.error('Failed updateStopStat'))
  }

  const updateLoopState = async (loop: boolean) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.loop`]: loop,
      })
      .catch(() => console.error('Failed updateLoopState'))
  }

  const updateSeekState = async (seekToValueInSeconds: number) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.seekToValueInSeconds`]:
          seekToValueInSeconds,
      })
      .catch(() => console.error('Failed updateSeekState'))
  }

  const updateMuteState = async (muted: boolean) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.muted`]: muted,
      })
      .catch(() => console.error('Failed updateMuteState'))
  }

  const updateVolumeState = async (volume: number) => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.muted`]: false,
        [`${galleryName}.playerControlV2.volume`]: volume,
      })
      .catch(() => console.error('Failed updateVolumeState'))
  }

  const updateSyncState = async () => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.videoSyncKey`]: Date.now(),
      })
      .catch(() => console.error('Failed updateSyncState'))
  }

  const resetPlayerState = async () => {
    await firebaseControlQuery
      .update({
        [`${galleryName}.galleryControlV2.activeSlideIndex`]: 0,
        [`${galleryName}.playerControlV2.scrubbarTimerState`]: false,
        [`${galleryName}.playerControlV2.playerState`]: PlayerState.Stop,
        [`${galleryName}.playerControlV2.seekToValueInSeconds`]: null,
        [`${galleryName}.playerControlV2.videoLengthInSeconds`]: 0,
        [`${galleryName}.playerControlV2.currentVideoPositionInSeconds`]: 0,
        [`${galleryName}.playerControlV2.videoSyncKey`]: null,
      })
      .catch(() => console.error('Failed resetPlayerState'))
  }

  return {
    updatePlayState,
    updatePauseState,
    updateStopState,
    updateLoopState,
    updateSeekState,
    updateMuteState,
    updateVolumeState,
    updateSyncState,
    resetPlayerState,
  }
}

export default VideoPlayerFirebaseService
