/* eslint-disable react/prop-types */
import { Location } from 'history'
import React from 'react'
import { Range, getTrackBackground } from 'react-range'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import { SimplifiedThemeConfig } from '@api/config'

import { isNotSessionSettingsOrTools } from '@utilities/helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import {
  FastForwardSvg,
  PauseSvg,
  PlaySvg,
  RewindSvg,
  StopSvg,
} from '@svg/react'

export interface ComponentPropsInterface {
  galleryName: string
  playerSession: PlayerControlInterface | undefined
  projectIdentity: ProjectIdentity
  className?: string
  showSeekControls?: boolean
  themeConfig: SimplifiedThemeConfig
}

function ScrubBar({
  galleryName,
  playerSession,
  projectIdentity,
  className,
  showSeekControls,
  themeConfig,
}: ComponentPropsInterface) {
  const history = useHistory()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [videoLengthInMilliseconds, setVideoLengthInMilliseconds] =
    React.useState(100)
  const [playerState, setPlayerState] = React.useState('')
  const [isBuffering, setBufferStatus] = React.useState(false)
  const [isSeeking, setIsSeeking] = React.useState(false)
  const [
    currentVideoPositionInMilliseconds,
    setCurrentVideoPositionInMilliseconds,
  ] = React.useState(-1)

  const [rangeValue, setRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const videoPlayerStateRef = React.useRef<string>()
  const rangeValueRef = React.useRef<number>(0)

  const updateScrubPositionInFirebase = async (value: number) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: value,
    })
  }

  const updateCurrentVideoPositionInSecondsInFirebase = async (
    value: number
  ) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]:
        value / 1000,
    })
  }

  const resetCurrentVideoAndScrubPositionInSecondsInFirebase = async () => {
    setCurrentVideoPositionInMilliseconds(-1)
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]: -1,
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: -1,
    })
  }

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  const setTimer = () => {
    if (!intervalId) {
      setIntervalId(
        setInterval(() => {
          setRangeValue((previousValue) => {
            const shouldVideoLoop =
              videoLengthInMilliseconds > 0 &&
              previousValue >= videoLengthInMilliseconds &&
              playerState === 'pause'
            const value = shouldVideoLoop ? 0 : previousValue + 250
            rangeValueRef.current = value
            return value
          })
        }, 250)
      )
    }
  }

  const handleOnChange = (valueInMilliseconds: number) => {
    resetTimer()
    setRangeValue(valueInMilliseconds)
    if (videoPlayerStateRef.current === 'pause') {
      rangeValueRef.current = valueInMilliseconds
    }
  }

  const handleOnChangeComplete = async (valueInMilliseconds: number) => {
    await updateScrubPositionInFirebase(valueInMilliseconds / 1000)
  }

  const handleStopClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'stop',
      [`${galleryName}.playerControl.isPlayerVisible`]: false,
    })
    await resetCurrentVideoAndScrubPositionInSecondsInFirebase()
  }

  const handlePlayClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'play',
    })
    await updateScrubPositionInFirebase(rangeValue / 1000)
  }

  const handlePauseClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'pause',
    })
  }

  const handleFastForward = async () => {
    if (isSeeking) return

    setIsSeeking(true)

    if (rangeValue + (15 + 1) * 1000 > videoLengthInMilliseconds) {
      await handleOnChangeComplete(videoLengthInMilliseconds)
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.playerState`]: 'pause',
      })
      handleOnChange(videoLengthInMilliseconds)
    } else {
      await handleOnChangeComplete(rangeValue + 15 * 1000)
      handleOnChange(rangeValue + 15 * 1000)
    }

    setIsSeeking(false)
  }

  const handleRewind = async () => {
    if (isSeeking) return

    setIsSeeking(true)

    await handleOnChangeComplete(Math.max(rangeValue - 15 * 1000, 0))
    if (rangeValue - 15 * 1000 <= 0) {
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.playerState`]: 'pause',
      })
    }
    handleOnChange(Math.max(rangeValue - 15 * 1000, 0))

    setIsSeeking(false)
  }

  React.useEffect(() => {
    if (playerState === 'pause') {
      resetTimer()
    }

    if (playerState === 'stop') {
      resetTimer()
      resetCurrentVideoAndScrubPositionInSecondsInFirebase()
    }
  }, [playerState, rangeValue])

  React.useEffect(() => {
    if (isBuffering) {
      resetTimer()
    }
  }, [isBuffering])

  React.useEffect(() => {
    if (currentVideoPositionInMilliseconds >= 0) {
      setRangeValue(currentVideoPositionInMilliseconds)
      rangeValueRef.current = currentVideoPositionInMilliseconds
    }
  }, [currentVideoPositionInMilliseconds])

  React.useEffect(() => {
    if (playerState === 'play' && !isBuffering && !intervalId) {
      setTimer()
    }
  }, [currentVideoPositionInMilliseconds, playerState, isBuffering])

  React.useEffect(() => {
    if (rangeValue > videoLengthInMilliseconds) {
      setRangeValue(0)
    }
  }, [rangeValue])

  React.useEffect(() => {
    if (playerSession) {
      const {
        playerState: playerStateFirebase,
        videoLengthInSeconds: videoLengthInSecondsFirebase,
        isBuffering: isBufferingFirebase,
        currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
        videoSynchronizeTriggerKey: videoSynchronizeTriggerKeyInFirebase,
      } = playerSession

      if (videoSynchronizeTriggerKeyInFirebase) {
        if (galleryName === 'unitGallery') {
          updateScrubPositionInFirebase(rangeValueRef.current / 1000)
        }
        if (galleryName === 'videoGallery') {
          updateCurrentVideoPositionInSecondsInFirebase(rangeValueRef.current)
        }

        firebaseControlQuery.update({
          [`${galleryName}.playerControl.videoSynchronizeTriggerKey`]: false,
        })
      }

      setPlayerState(playerStateFirebase)
      videoPlayerStateRef.current = playerStateFirebase

      setBufferStatus(isBufferingFirebase)
      setVideoLengthInMilliseconds(videoLengthInSecondsFirebase * 1000)
      setCurrentVideoPositionInMilliseconds(
        currentVideoPositionInSecondsFirebase * 1000
      )
    }
  }, [
    playerSession?.playerState,
    playerSession?.videoLengthInSeconds,
    playerSession?.isBuffering,
    playerSession?.currentVideoPositionInSeconds,
    playerSession?.videoSynchronizeTriggerKey,
  ])

  React.useEffect(() => {
    const updateVideoPositionOnUnmount = () => {
      if (
        videoPlayerStateRef.current === 'pause' ||
        videoPlayerStateRef.current === 'play'
      ) {
        updateCurrentVideoPositionInSecondsInFirebase(
          rangeValueRef.current
        ).catch((error) => console.error(error))
      }
    }

    const handleRouteChange = (location: Location) => {
      const { pathname } = location
      if (isNotSessionSettingsOrTools(pathname)) {
        handleStopClick().catch((error) => console.error(error))
      }
    }

    const handleBeforeUnload = () => {
      if (
        videoPlayerStateRef.current === 'pause' ||
        videoPlayerStateRef.current === 'play'
      ) {
        updateCurrentVideoPositionInSecondsInFirebase(
          rangeValueRef.current
        ).catch((error) => console.error(error))
      }
    }

    const unsubscribeHistory = history.listen(handleRouteChange)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      updateVideoPositionOnUnmount()
      unsubscribeHistory()
      window.removeEventListener('beforeunload', handleBeforeUnload)
      clearInterval(intervalId)
    }
  }, [history])

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {playerState === 'pause' ? (
        <button type="button" onClick={() => handlePlayClick()}>
          <PlaySvg className="h-5 w-5 cursor-pointer text-mainColour" />
        </button>
      ) : (
        <button type="button" onClick={() => handlePauseClick()}>
          <PauseSvg
            className="h-5 w-5 cursor-pointer text-mainColour"
            stroke={1.2}
          />
        </button>
      )}

      <button type="button" onClick={() => handleStopClick()}>
        <StopSvg
          className="h-9 w-9 cursor-pointer text-mainColour"
          stroke={1.2}
        />
      </button>

      {showSeekControls && (
        <>
          <button type="button" onClick={() => handleRewind()}>
            <RewindSvg className="h-6 w-6 cursor-pointer text-mainColour" />
          </button>

          <button type="button" onClick={() => handleFastForward()}>
            <FastForwardSvg className="h-6 w-6 cursor-pointer text-mainColour" />
          </button>
        </>
      )}

      <div className="w-full px-3">
        <Range
          min={0}
          max={videoLengthInMilliseconds}
          step={0.1}
          values={[rangeValue]}
          onChange={(value) => handleOnChange(value[0] || 0)}
          onFinalChange={(value) => handleOnChangeComplete(value[0] || 0)}
          renderTrack={({ props, children }) => (
            <div
              role="slider"
              tabIndex={0}
              aria-valuemin={0}
              aria-valuemax={videoLengthInMilliseconds}
              aria-valuenow={rangeValue}
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '30px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                className="h-1 w-full rounded-lg"
                style={{
                  background: getTrackBackground({
                    values: [rangeValue],
                    colors: [
                      themeConfig.mainColour || '',
                      themeConfig.neutralColour || '',
                    ],
                    min: 0,
                    max: videoLengthInMilliseconds,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...props}
              key={props.key}
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor: themeConfig.mainColour || '',
              }}
            ></div>
          )}
        />
      </div>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(ScrubBar)
