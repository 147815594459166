import React from 'react'

import { MenuItemInterface } from '@store/types'

import Branding from '@components/branding/Branding'

interface ComponentPropsInterface {
  menuItems: Array<MenuItemInterface>
  handleClick: (arg: MenuItemInterface) => void
  children?: React.ReactNode
}

function BottomNavigation({
  menuItems,
  handleClick,
  children,
}: ComponentPropsInterface) {
  const { topMenus, mainMenus } = React.useMemo(
    () =>
      menuItems.reduce<{
        topMenus: MenuItemInterface[]
        mainMenus: MenuItemInterface[]
      }>(
        (a, c) => {
          if (c.active && c.type === 'footer-menu') a.topMenus.push(c)
          if (c.type === 'main-menu') a.mainMenus.push(c)
          return a
        },
        { topMenus: [], mainMenus: [] }
      ),
    [menuItems]
  )

  return (
    <div className="relative flex h-full w-full flex-col justify-between">
      <div className="z-10 grid grid-cols-4 justify-end gap-[15px] p-5">
        <Branding className="-ml-1 -mt-1" />
        {topMenus.map((menu, index) =>
          menu.render ? (
            menu.render({
              menu,
              key: menu.key,
              className:
                'rounded-lg bg-gradient px-4 py-3 text-xl font-medium text-neutralColour ipad-pro:py-6 ipad-pro:text-3xl ipad-mini-land:py-6 ipad-mini-land:text-3xl',
              style: { gridColumn: -topMenus.length + index - 1, gridRow: 1 },
              tabIndex: index,
            })
          ) : (
            <button
              key={menu.key}
              className="rounded-lg bg-gradient px-4 py-3 text-xl font-medium text-neutralColour ipad-pro:py-6 ipad-pro:text-3xl ipad-mini-land:py-6 ipad-mini-land:text-3xl"
              style={{ gridColumn: -topMenus.length + index - 1, gridRow: 1 }}
              onClick={() => handleClick(menu)}
              type="button"
              tabIndex={index}
            >
              <span>{menu.label}</span>
            </button>
          )
        )}
      </div>
      {children}
      <div className="z-10 grid grid-cols-4 gap-[15px] rounded-tl-lg rounded-tr-lg bg-neutralColour p-5">
        {mainMenus.map((menu, index) => (
          <button
            key={menu.key}
            className={`rounded-lg bg-gradient px-4 py-7 text-xl font-medium ipad-pro:py-12 ipad-pro:text-3xl ipad-mini-land:py-12 ipad-mini-land:text-3xl ${
              menu.active
                ? 'text-neutralColour'
                : 'text-tertiaryColour opacity-[.35]'
            }`}
            onClick={() => handleClick(menu)}
            type="button"
            tabIndex={index}
            disabled={!menu.active}
          >
            <span>{menu.label}</span>
          </button>
        ))}
      </div>
    </div>
  )
}

export default BottomNavigation
