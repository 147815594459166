import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'

import firebaseConfig, { getFirebaseLocalHost } from './firebaseConfig'

const app = initializeApp(firebaseConfig)

const firestore = getFirestore(app)

const { firebasePort, firebaseHost } = getFirebaseLocalHost()

if (firebasePort && firebaseHost) {
  connectFirestoreEmulator(
    firestore,
    firebaseHost || 'localhost',
    Number(firebasePort)
  )
}

export default firestore
