import React from 'react'

import { SvgProps } from './types'

function SpeakerXMarkSvg({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 42 42"
      stroke="currentColor"
    >
      <g clipPath="url(#clip0_345_6422)">
        <mask
          id="mask0_345_6422"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="42"
        >
          <path d="M0 0H42V42H0V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_345_6422)">
          <path
            d="M31.5 24.5L35 21M35 21L38.5 17.5M35 21L31.5 17.5M35 21L38.5 24.5"
            stroke="currentColor"
            strokeWidth={stroke || 3.25}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 24.2497V17.7502C3.5 16.822 3.86875 15.9317 4.52513 15.2753C5.1815 14.619 6.07174 14.2502 7 14.2502H12.075C12.4172 14.2501 12.7518 14.1497 13.0375 13.9615L23.5375 7.03846C23.8013 6.86472 24.1072 6.76563 24.4228 6.7517C24.7384 6.73777 25.0519 6.80952 25.33 6.95934C25.6081 7.10915 25.8404 7.33145 26.0024 7.60264C26.1644 7.87384 26.2499 8.18382 26.25 8.49971V33.5002C26.2499 33.8161 26.1644 34.1261 26.0024 34.3973C25.8404 34.6685 25.6081 34.8908 25.33 35.0406C25.0519 35.1904 24.7384 35.2622 24.4228 35.2482C24.1072 35.2343 23.8013 35.1352 23.5375 34.9615L13.0375 28.0385C12.7518 27.8502 12.4172 27.7498 12.075 27.7497H7C6.07174 27.7497 5.1815 27.381 4.52513 26.7246C3.86875 26.0682 3.5 25.178 3.5 24.2497Z"
            stroke="currentColor"
            strokeWidth={stroke || 3.25}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_345_6422">
          <rect width="42" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SpeakerXMarkSvg
