import React from 'react'
import { PhoneInputRefType } from 'react-international-phone'

import { LoadingSvg } from '@components/adgroup-svg/react'
import { Modal } from '@components/modals'
import PhoneInput from '@components/phone-input/phone-input'

interface AddAgentFormProps {
  show: boolean
  onToggle: (status: boolean) => void
  onConfirm: (values: Record<string, string>) => void
  isProcessing?: boolean
  errors?: string[]
}

const initialFormValue = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
}

function AddAgentForm({
  show,
  onToggle,
  onConfirm,
  isProcessing,
  errors,
}: AddAgentFormProps) {
  const [formValue, setFormValue] = React.useState(initialFormValue)
  const [phoneInputValidity, setPhoneInputValidity] = React.useState(false)
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false)
  const phoneInputRef = React.useRef<PhoneInputRefType>(null)

  const handleInput: (
    index: keyof typeof initialFormValue
  ) => React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (index) => (e) => {
      setFormValue((prevVal) => ({
        ...prevVal,
        [index]: e.target.value,
      }))
    },
    []
  )

  const handlePhoneInput = React.useCallback((value: string) => {
    setFormValue((prevVal) => ({
      ...prevVal,
      phone: value,
    }))
  }, [])

  const handleSubmit: React.FormEventHandler = React.useCallback(
    (e) => {
      e.preventDefault()
      if (!phoneInputValidity) {
        return
      }
      onConfirm(formValue)
    },
    [onConfirm, formValue, phoneInputValidity]
  )

  const resetForm = React.useCallback(() => {
    setFormValue({ ...initialFormValue })
    setPhoneInputValidity(false)
    setHasClickedSubmit(false)

    if (phoneInputRef.current) {
      phoneInputRef.current.setCountry('au')
    }
  }, [])

  React.useEffect(() => {
    if (show) {
      resetForm()
    }
  }, [show])

  return (
    <Modal
      toggleModal={onToggle}
      isVisible={show}
      modalWidth="max-w-lg"
      title="Add a new agent"
      noMainControls
      theme={{
        textColor: 'text-mainColour',
        mainBg: '',
        secondaryBg: '',
      }}
    >
      <form onSubmit={handleSubmit} className="mt-4 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="text-left">
            <h4 className="text-[25px] font-bold text-mainColour">NAME</h4>
            <div className="flex gap-4">
              <input
                className="my-2 w-full rounded-lg border-gray-200 px-4 py-3 text-xl text-mainColour placeholder:text-grayColour focus:border-mainColour focus:ring-0"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formValue.firstName}
                onChange={handleInput('firstName')}
                required
              />
              <input
                className="my-2 w-full rounded-lg border-gray-200 px-4 py-3 text-xl text-mainColour placeholder:text-grayColour focus:border-mainColour focus:ring-0"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formValue.lastName}
                onChange={handleInput('lastName')}
                required
              />
            </div>
          </div>
          <div className="text-left">
            <h4 className="text-[25px] font-bold text-mainColour">PHONE</h4>
            <PhoneInput
              className="my-2"
              ref={phoneInputRef}
              value={formValue.phone}
              onChange={handlePhoneInput}
              onValidityChange={(status) => setPhoneInputValidity(status)}
              showInputError={hasClickedSubmit}
              required
            />
          </div>
          <div className="text-left">
            <h4 className="text-[25px] font-bold text-mainColour">EMAIL</h4>
            <input
              className="my-2 w-full rounded-lg border-gray-200 px-4 py-3 text-xl text-mainColour placeholder:text-grayColour focus:border-mainColour focus:ring-0"
              type="email"
              name="email"
              placeholder="agent.doe@gmail.com"
              value={formValue.email}
              onChange={handleInput('email')}
              required
            />
          </div>
        </div>
        {errors?.length && (
          <div>
            <ul className="list-disc text-left text-sm text-rose-800">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex justify-between gap-4">
          <button
            type="button"
            className="flex-1 rounded-lg border px-4 py-3 text-3xl font-medium text-mainColour"
            onClick={() => onToggle(false)}
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 rounded-lg bg-gradient px-4 py-3 text-3xl font-medium text-white shadow-standard"
            disabled={isProcessing}
            onClick={() => setHasClickedSubmit(true)}
          >
            <div className="flex items-center justify-center gap-2">
              {isProcessing && <LoadingSvg className="h-5 w-5" />}
              <span>Save</span>
            </div>
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default AddAgentForm
