import React from 'react'

interface ComponentPropsInterface {
  isVisible: boolean
  toggleModal: (arg: boolean) => void
  title?: string
  message?: string
  closeButtonText?: string
}

function InfoModal({
  isVisible = false,
  toggleModal,
  title = '',
  message = '',
  closeButtonText = 'Close',
}: ComponentPropsInterface) {
  return (
    <div
      className={`absolute left-0 top-0 z-25 flex h-full w-full items-center justify-center bg-gray-800/80 transition-all duration-300 ease-in-out ${
        isVisible ? 'visible opacity-100' : 'invisible opacity-0'
      } `}
    >
      <div className="w-1/2 drop-shadow-2xl">
        <div className="rounded-lg bg-secondaryColour p-6">
          {title && (
            <div className="mb-6 text-center text-heading font-medium text-mainColour">
              {title}
            </div>
          )}
          {message && (
            <div className="mb-6 text-center text-default text-mainColour">
              {message}
            </div>
          )}
          <button
            type="button"
            className="cursor-hand mt-4 w-full rounded-lg bg-mainColour py-8 text-center text-subHeading font-medium text-secondaryColour"
            onClick={() => toggleModal(false)}
          >
            {closeButtonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InfoModal
