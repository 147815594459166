import React from 'react'

import { SvgProps } from './types'

function DevelopmentIDSvg({ width, height, className }: SvgProps) {
  return (
    <svg
      className={className}
      width={width || '64'}
      height={height || '64'}
      fill="none"
      viewBox="0 0 64 64"
    >
      <g clipPath="url(#clip0_807_3812)">
        <path
          opacity="0.979"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.83496 0.0614451C7.92365 0.0409795 10.0119 0.061453 12.0998 0.122865C12.2022 0.225232 12.3046 0.3276 12.4069 0.429967C12.4889 16.3173 12.4889 32.2048 12.4069 48.0922C12.3455 48.1536 12.2841 48.215 12.2227 48.2764C10.0934 48.3584 7.96419 48.3584 5.83496 48.2764C5.73259 48.1741 5.63022 48.0716 5.52785 47.9693C5.44596 32.1229 5.44596 16.2764 5.52785 0.429967C5.65622 0.322673 5.75859 0.199833 5.83496 0.0614451Z"
          fill="white"
        />
        <path
          opacity="0.993"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.9386 21.5585C63.9386 23.3602 63.9386 25.1618 63.9386 26.9635C62.2329 37.3714 56.3365 44.1482 46.2496 47.2936C44.7137 47.6909 43.1577 47.9775 41.5816 48.1535C35.1548 48.3496 28.7261 48.3905 22.2956 48.2764C22.2342 48.215 22.1728 48.1535 22.1114 48.0921C22.0294 32.1637 22.0294 16.2355 22.1114 0.307083C22.1728 0.245662 22.2342 0.184242 22.2956 0.122822C27.7826 0.0409279 33.2694 0.0409279 38.7563 0.122822C46.5336 0.174104 53.0647 3.01991 58.3494 8.66025C61.4888 12.401 63.3519 16.7004 63.9386 21.5585ZM28.929 7.06332C32.4508 7.04286 35.9721 7.06332 39.4933 7.12474C46.4685 7.56861 51.4844 10.9058 54.5413 17.1363C57.0626 23.6645 56.2436 29.7246 52.0845 35.3167C49.1438 38.5992 45.479 40.5443 41.0903 41.1516C37.0393 41.3256 32.9856 41.387 28.929 41.3359C28.929 29.9117 28.929 18.4875 28.929 7.06332Z"
          fill="white"
        />
        <path
          opacity="0.986"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.0614014 56.9366C21.19 56.9161 42.4415 56.9366 63.6929 56.9981C63.7597 57.0861 63.8417 57.1476 63.9386 57.1823C63.9386 59.3526 63.9386 61.5227 63.9386 63.6929C63.8158 63.7338 63.7338 63.8157 63.6929 63.9386C42.4415 63.9386 21.19 63.9386 -0.0614014 63.9386C-0.0614014 61.6046 -0.0614014 59.2706 -0.0614014 56.9366Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_807_3812">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default DevelopmentIDSvg
