import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setLoadingStatus } from '@store/actionSlices/statusIndicator'
import {
  ExtendedUnitFilterOptions,
  ProjectIdentity,
  RootStateTypeExtra,
  SnaploaderInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'

import { Level, selectFromResult, useGetBuildingQuery } from '@api/building'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { ChevronSvg, FunnelSvg, PauseSvg, PlaySvg } from '@svg/react'

import filterUnit from '@adUtilities/filter-unit'
import { Unit } from '@adUtilities/types/apartment'

import BlockList from './block-list'
import LevelList from './level-list'
import SpanloaderSkeleton from './snaploader-skeleton'

export interface SnaploaderViewProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function Snaploader({ projectIdentity, session }: SnaploaderViewProps) {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const [snaploaderState, setSnaploaderState] =
    React.useState<SnaploaderInterface>()
  const [unitFilter, setUnitFilter] =
    React.useState<ExtendedUnitFilterOptions>()
  const [isFilterOpen, toggleFilter] = React.useState<boolean>(false)
  const [activeBlock, setActiveBlock] = React.useState('')
  const [activeView, setActiveView] = React.useState('')

  const handleToggleRotateCamera = async () => {
    await firebaseControlQuery.update({
      [`snaploader.rotateCamera`]: !snaploaderState?.rotateCamera,
    })
  }

  const handleToggleBlock = async (viewState: string) => {
    await firebaseControlQuery.update({
      [`snaploader.activeView`]: viewState,
      [`snaploader.activeBlock`]:
        viewState.split('-')?.[1]?.replace(/\s/g, '') ?? '',
    })
  }

  const handleLevelSelect = async (level: string) => {
    toggleFilter(false)

    const splicedGroupId = level.split('_')
    const selectedLevel = buildingPayload.levels.find(
      (lvl: Level) => splicedGroupId[1] === lvl.level
    )
    if (!selectedLevel) {
      return
    }
    const unit = selectedLevel.data.find(
      (unt: Unit) =>
        (unt?.blockId || '').replace(/\s/g, '') === splicedGroupId[0]
    )
    if (!unit) {
      return
    }

    if (unit.blockId) {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: unit.blockId,
        [`building.activeLevel`]: splicedGroupId[1],
      })
    } else {
      await firebaseControlQuery.update({
        [`building.activeLevel`]: level,
      })
    }
    await firebaseControlQuery.updateRoute('building')
  }

  const handleBackButtonPress = async () => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
      [`building.unitFilter.apply`]: false,
      [`snaploader.activeBlock`]: '',
      [`snaploader.activeView`]: '',
    })
  }

  const getFilteredData = (levels: Level[]): Array<string> => {
    const filteredLevelList: Array<string> = []

    levels.forEach((level) => {
      level.data.forEach((unit: Unit) => {
        let identifier = ''
        if (unit.blockId && unit.blockId !== '') {
          identifier += `${unit.blockId.replace(/\s/g, '')}_`
        }
        identifier += level.level
        if (!filteredLevelList.includes(identifier) && unitFilter) {
          const response = unitFilter.apply
            ? filterUnit(
                unit,
                unitFilter,
                projectIdentity.showPrice,
                projectIdentity?.statusLabels?.available
              )
            : true

          if (response) {
            filteredLevelList.push(identifier)
          }
        }
      })
    })

    return filteredLevelList
  }

  React.useEffect(() => {
    if (session) {
      const {
        snaploader,
        building: { unitFilter: unitFilterFirebase },
      } = session

      setSnaploaderState(snaploader)
      setActiveBlock(snaploader.activeBlock || '')
      setActiveView(snaploader.activeView || '')
      setUnitFilter(unitFilterFirebase)

      dispatch(setLoadingStatus(!snaploader.isLoaded))
    }
  }, [session])

  const blockViewsOptions = React.useMemo(
    () =>
      snaploaderState?.data.views.map((item) => ({
        label: item,
        value: item,
      })) ?? [],
    [snaploaderState?.data.views]
  )

  const levelsOptions = React.useMemo(() => {
    let optionsResult = unitFilter?.apply
      ? getFilteredData(buildingPayload.levels).map((item) => ({
          label: item,
          value: item,
        }))
      : snaploaderState?.data.levels.map((item) => ({
          label: item.key,
          value: item.value,
        }))

    optionsResult = optionsResult?.filter(
      (item) => item.value.split('_')?.[0] === activeBlock
    )

    return optionsResult
  }, [
    snaploaderState?.data.levels,
    snaploaderState?.activeBlock,
    unitFilter?.apply,
    buildingPayload,
  ])

  return (
    <Container>
      {!projectIdentity.hideFilter && session && (
        <Filter
          toggle={toggleFilter}
          isOpen={isFilterOpen}
          firebaseUnitFilter={session.building.unitFilter}
          firebaseActiveBlock={session.building.activeBlock}
          firebaseActiveLevel={session.building.activeLevel}
          hideLevel
        />
      )}

      <div className="h-full w-full px-4">
        <div className="h-top-bar w-full py-3 text-secondaryColour">
          <div
            className={`flex h-[40%] items-start ${
              activeView ? 'justify-between' : 'justify-end'
            }`}
          >
            {activeView && (
              <button
                onClick={handleBackButtonPress}
                type="button"
                className="relative flex items-center text-default font-normal text-secondaryColour"
              >
                <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

                <span className="ml-6">Buildings</span>
              </button>
            )}
            <button
              type="button"
              onClick={handleToggleRotateCamera}
              className="inline-flex items-center text-default font-normal"
            >
              <span className="mr-2">Auto-rotate</span>
              {snaploaderState?.rotateCamera ? <PauseSvg /> : <PlaySvg />}
            </button>
          </div>
          <div className="flex h-[60%] items-baseline justify-between">
            <div className="text-title font-medium">
              {activeView || 'Buildings'}
            </div>
            {!projectIdentity.hideFilter && activeView && (
              <button
                type="button"
                onClick={() => toggleFilter(true)}
                className="inline-flex items-center text-default font-normal"
              >
                <span className="mr-1">Filters</span>
                <FunnelSvg className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>

        <div className="no-scrollbar h-page-body w-full overflow-auto">
          <DataHandler
            payload={{
              ...buildingPayload,
              data: buildingPayload.levels,
            }}
            skeletonFrame={<SpanloaderSkeleton />}
          >
            {activeView ? (
              <LevelList
                levels={levelsOptions || []}
                handleClick={handleLevelSelect}
                buildingLevels={buildingPayload.levels}
                onlyShowAvailable={Boolean(
                  session?.building?.unitFilter?.showAvailable &&
                    session?.building?.unitFilter?.apply
                )}
                availableStatusLabel={projectIdentity?.statusLabels?.available}
              />
            ) : (
              <BlockList
                blocks={blockViewsOptions}
                handleClick={handleToggleBlock}
              />
            )}
          </DataHandler>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(Snaploader)
