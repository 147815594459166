import React from 'react'

function LotSkeleton() {
  return (
    <div className="h-full w-full p-5">
      <div className="flex py-4">
        <div className="cursor-hand h-16 w-96 rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>

      <div className="flex h-page-body gap-4 pb-4">
        <div className="flex flex-grow flex-col gap-3">
          <div className="w-full flex-grow cursor-pointer rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full w-full opacity-50"></div>
          </div>
          <div className="w-full flex-grow cursor-pointer rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full w-full opacity-50"></div>
          </div>
        </div>
        <div className="flex flex-grow flex-col gap-3">
          <div className="w-full flex-grow cursor-pointer rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full w-full opacity-50"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LotSkeleton
