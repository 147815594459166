import React from 'react'

import { FloorTabInterface } from '@store/types'

import { floorTabItems } from '@utilities/floor-tab'

interface ComponentPropsInterface {
  activeTab: string
  handleClick: (arg: string) => void
}

function FloorGalleryTab({ activeTab, handleClick }: ComponentPropsInterface) {
  return (
    <div className="flex">
      {floorTabItems().map((tab: FloorTabInterface) => (
        <button
          key={tab.key}
          type="button"
          className={`text-md border first:rounded-l-lg last:rounded-r-lg ${
            activeTab === tab.key
              ? 'border-transparent bg-gradient font-medium text-white underline underline-offset-4'
              : 'border-mainColour bg-white text-mainColour'
          } px-3 py-2 tracking-widest`}
          onClick={() => handleClick(tab.key)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  )
}

export default FloorGalleryTab
