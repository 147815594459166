import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

export interface AppConfigInterface {
  remoteRefreshTriggerKey: string
  offlineMode: boolean
  firebaseHost: string
  firebasePort: string
  room: string
  precinctLabel: string
}

const defaultAppConfig = {
  remoteRefreshTriggerKey: '',
  offlineMode: false,
  firebaseHost: '',
  firebasePort: '',
  room: '',
  precinctLabel: '',
}

let initialState = defaultAppConfig as AppConfigInterface

const appConfig = localStorage.getItem('appConfig')

if (appConfig) {
  initialState = JSON.parse(appConfig)
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setRemoteRefreshTriggerKey: (
      state: AppConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ remoteRefreshTriggerKey: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    setOfflineMode: (
      state: AppConfigInterface,
      action: PayloadAction<boolean>
    ) => {
      const newState = {
        ...state,
        ...{ offlineMode: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    setFirebaseHost: (
      state: AppConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ firebaseHost: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    setFirebasePort: (
      state: AppConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ firebasePort: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    setRoom: (state: AppConfigInterface, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        ...{ room: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    setPrecinctLabel: (
      state: AppConfigInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ precinctLabel: action.payload },
      }
      localStorage.setItem('appConfig', JSON.stringify(newState))
      return newState
    },
    resetAppConfig: () => {
      localStorage.removeItem('appConfig')
      return defaultAppConfig
    },
  },
})

export const {
  setRemoteRefreshTriggerKey,
  setOfflineMode,
  setFirebaseHost,
  setFirebasePort,
  setRoom,
  setPrecinctLabel,
  resetAppConfig,
} = appConfigSlice.actions

export const selectAppConfig = (state: RootStateTypeExtra) => state.appConfig

export default appConfigSlice.reducer
