import React from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { Modal } from '@components/modals'

import ShortlistHandler from '@utilities/shortlist-handler'

import { EyeCloseSvg, InfoCircleSvg, LinkSvg, StarSvg } from '@svg/react'

import { formatAreaNumber } from '@adUtilities/helper'
import { Unit } from '@adUtilities/types/apartment'

interface ComponentPropsInterface {
  unit: Unit
  shortlist: ShortlistState
  handleInfoPopup: () => void
  unitInfoPopup: boolean
  handleExternalLinkPopup: () => void
  showExternalLink: boolean
  toggleExternalLinkPopup: boolean
  showPrice: boolean
  hideSoldPrice: boolean
  children: React.ReactNode
  projectIdentity: ProjectIdentity
  availableStatusLabel: string
}

function UnitDetailsView({
  unit,
  shortlist,
  handleInfoPopup,
  unitInfoPopup,
  handleExternalLinkPopup,
  showExternalLink,
  toggleExternalLinkPopup,
  children,
  showPrice,
  hideSoldPrice,
  projectIdentity,
  availableStatusLabel,
}: ComponentPropsInterface) {
  const LABEL = {
    bed: 'Bedrooms',
    bath: 'Bathrooms',
    study: 'Study',
    car: 'Car spaces',
    powderRoom: 'Powder Room',
  }
  const MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST) || 4

  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')
  const [showConfirmModal, toggleConfirmModal] = React.useState(false)
  const [removeShortlistProperty, setRemoveShortlistProperty] =
    React.useState(false)
  const property = React.useMemo<UnitShortlistInterface>(
    () => ({
      propertyId: unit.id,
      propertyName: unit.name,
      references: {
        blockId: unit.blockId,
        levelId: unit.metas.level,
      },
      type: ShortlistPropertyType.Unit,
    }),
    [unit]
  )

  const disabledShortlist =
    shortlist.shortlists.length >= MAXIMUM_PROPERTY_LENGTH_FOR_SHORTLIST

  const setShortlistErrorMessage = (message: string) => {
    setAlertModalMessage(message)
    toggleAlertModal(true)
  }

  const [isShortlisted, handleShortlistClick] = ShortlistHandler({
    property,
    shortlist,
    setShortlistErrorMessage,
    projectIdentity,
    toggleConfirmModal,
    removeShortlistProperty,
    setRemoveShortlistProperty,
    available: unit.metas.status,
  })

  const isPriceVisible =
    showPrice && !(unit?.metas.status !== availableStatusLabel && hideSoldPrice)

  return (
    <>
      <div className="flex h-full max-h-[890px] gap-[15px] text-default font-normal text-mainColour">
        <div className="flex w-full flex-col gap-[15px]">
          <div
            className={`relative rounded-lg ${
              showExternalLink ? 'h-[55%]' : 'h-[60%]'
            }`}
          >
            {children}
          </div>
          <button
            className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-gradient text-center text-subHeading font-medium text-neutralColour shadow-standard ${
              unit.metas.status !== availableStatusLabel || disabledShortlist
                ? 'opacity-60'
                : ''
            } ${showExternalLink ? 'h-[15%]' : 'h-[20%]'}`}
            type="button"
            onClick={handleShortlistClick}
          >
            <span>Add to shortlist</span>
            {unit.metas.status !== availableStatusLabel || disabledShortlist ? (
              <StarSvg className="h-8 w-8 stroke-neutralColour text-transparent" />
            ) : (
              <StarSvg
                className={`h-8 w-8 ${
                  isShortlisted
                    ? 'stroke-amber-500 text-amber-500'
                    : 'stroke-neutralColour text-transparent'
                }`}
                stroke={isShortlisted ? 0 : 2}
              />
            )}
          </button>

          {showExternalLink && (
            <button
              className="flex h-[15%] items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-gradient text-center text-subHeading font-medium text-neutralColour shadow-standard"
              type="button"
              onClick={handleExternalLinkPopup}
            >
              <span>
                {toggleExternalLinkPopup
                  ? 'Hide external link'
                  : 'Load external link'}
              </span>
              <LinkSvg className="h-8 w-8 text-neutralColour" />
            </button>
          )}

          <button
            className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-gradient text-center text-subHeading font-medium text-neutralColour shadow-standard ${
              showExternalLink ? 'h-[15%]' : 'h-[20%]'
            }`}
            type="button"
            onClick={handleInfoPopup}
          >
            <span>
              {unitInfoPopup ? 'Hide information' : 'Show information'}
            </span>
            {unitInfoPopup ? (
              <EyeCloseSvg
                className="h-8 w-8 text-mainColour"
                strokeColor="#FFF7E9"
              />
            ) : (
              <InfoCircleSvg className="h-8 w-8 text-neutralColour" />
            )}
          </button>
        </div>
        <div className="flex h-full w-full flex-col gap-[15px] overflow-y-auto">
          <div className="grow-[4] rounded-lg bg-secondaryColour p-5">
            <h2 className="font-bold uppercase">Description</h2>
            <div className="mt-2 flex flex-col gap-1">
              <p>
                <span>Apartments: </span>
                <span>{unit.name || ''}</span>
              </p>
              {Object.entries(unit.metas.configurations || {}).map(
                ([field, value]) => {
                  if (!value) return null
                  return (
                    <p key={`${field}-${value}`}>
                      <span>{LABEL[field as keyof typeof LABEL]}: </span>
                      <span>{value}</span>
                    </p>
                  )
                }
              )}
              {unit.metas?.aspect && (
                <p>
                  <span>Aspect: </span>
                  <span>{unit.metas.aspect}</span>
                </p>
              )}
            </div>
          </div>

          {Number(unit?.metas?.price) > 0 && isPriceVisible && (
            <div className="rounded-lg bg-secondaryColour p-5">
              <h2 className="font-bold uppercase">Price</h2>
              <div className="mt-2 flex flex-col gap-1">
                <p>
                  <span>Price: </span>
                  <span>
                    ${Number(unit.metas.price).toLocaleString('en-US')}
                  </span>
                </p>
              </div>
            </div>
          )}

          <div className="grow-[3] rounded-lg bg-secondaryColour p-5">
            <h2 className="font-bold uppercase">Area</h2>
            <div className="mt-2 flex flex-col gap-1">
              <p>
                <span>Internal: </span>
                <span>
                  {formatAreaNumber(unit.metas.internalArea)} m<sup>2</sup>
                </span>
              </p>
              <p>
                <span>External: </span>
                <span>
                  {formatAreaNumber(unit.metas.externalArea)} m<sup>2</sup>
                </span>
              </p>
              <p>
                <span>Total: </span>
                <span>
                  {formatAreaNumber(unit.metas.totalArea)} m<sup>2</sup>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Message"
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        modalWidth="max-w-lg"
      />

      <Modal
        isVisible={showConfirmModal}
        toggleModal={toggleConfirmModal}
        message="Do you want to remove this item from the shortlist?"
        modalWidth="max-w-lg"
        title="Confirmation"
        applyButtonText="Yes"
        closeButtonText="No"
        handleApply={() => {
          setRemoveShortlistProperty(true)
        }}
      />
    </>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
  })
)(UnitDetailsView)
