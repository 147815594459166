import React from 'react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL || ''

export interface NoDataFoundProps {
  message?: string
}

function DataNotFound({ message = 'No data found.' }: NoDataFoundProps) {
  return (
    <img
      className="z-2 h-full w-full"
      src={AssetHandler({
        url: PLACEHOLDER_IMAGE,
        type: 'new',
      })}
      alt={message}
    />
  )
}

export default DataNotFound
