import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  HouseAndLandPackageOptionType,
  PackageConfigurationInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

import { roomQueryParams } from '@utilities/helper'

import { FileType } from '@adUtilities/constants/common'

export enum LotStatusType {
  Sold = 'sold',
  Available = 'available',
}

export enum HouseAndLandActiveKeyType {
  ActivePrecinctId = 'activePrecinctId',
  ActiveStageId = 'activeStageId',
  ActiveLotId = 'activeLotId',
  ActivePackageId = 'activePackageId',
  ActivePackageOption = 'activePackageOption',
}

export interface ConfigurationInterface {
  id?: string
  bed: number
  study: number
  bath: number
  powderRoom: number
  car: number
}

export interface MetaInterface {
  internalArea: number
  externalArea: number
  totalArea: number
  frontage: number
  isLeased: false
  hidePrice: false
  aspect: string
  configurations: ConfigurationInterface
  purchasePrice: number
  price: number
  status: LotStatusType
  pricem2: number
  hideSoldPrice: false
}

export interface LotAssetInterface {
  id: string
  name: string
  order: number
  projectId: string
  showcaseRoomId: string
  title: string
  type: FileType
  url: string
  uuid: string
}

export interface LotInterface {
  id: string
  area: string
  aspect: string
  frontage: number
  name: string
  price: number
  status: LotStatusType
  assets?: {
    floorImage?: LotAssetInterface
    viewImage?: LotAssetInterface[]
    viewLinesImage?: LotAssetInterface[]
  }
}

export interface ExtendedLotInterface extends LotInterface {
  precinctId: string
  stageId: string
  configuration?: PackageConfigurationInterface
}

export interface StageInterface {
  id: string
  order?: number
  label: string
  lots: Array<LotInterface>
}

export interface ExtendedStageInterface extends Omit<StageInterface, 'lots'> {
  groupId: string
  lots: Array<ExtendedLotInterface>
}

export interface PrecinctInterface {
  id: string
  label: string
  order: number
  stages: Array<StageInterface>
}

export interface GalleryInterface {
  id: string
  name: string
  title: string
  url: string
  type: FileType
  thumbnail?: string
  order: number
  position: number
}

export interface MatrixDataCollectionInterface {
  [key: string]: MatrixDataInterface
}

export interface PackageInterface {
  id: string
  name: string
  storey: number
  order: number
  houseSize: number
  housePrice: number
  builder: string
  title: string
  configuration: ConfigurationInterface
  galleries?: Array<GalleryInterface>
  files?: Array<FileInterface>
  floorplanOptions?: Array<FloorplanOptionInterface>
  facades?: Array<FacadeInterface>
  internalThemes?: Array<InternalThemeInterface>
}

export interface LotSummaryInterface {
  id: string
  name: string
  area: number
  frontage: number
  aspect: string
  price: number
  status: LotStatusType
}

export interface FileInterface {
  id: string
  name: string
  title: string
  url: string
  order: number
}

interface FloorplanOptionInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
}

interface FacadeColorInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  thumbnail: FileInterface
}

interface FacadeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  thumbnail: FileInterface
  facadeColors: Array<FacadeColorInterface>
}

interface InternalColorSchemeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
}

interface InternalThemeInterface {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  internalColorSchemes: Array<InternalColorSchemeInterface>
}

export interface PropertyOptionType {
  id: string
  name: string
  price: number
  images: Array<FileInterface>
  thumbnail: FileInterface
}

export interface PackageOption {
  id: string
  packageId: string
  floorplanOptions: Array<FloorplanOptionInterface>
  facades: Array<FacadeInterface>
  internalThemes: Array<InternalThemeInterface>
}

export interface StageCollectionInterface {
  [key: string]: StageInterface
}

export interface MatrixDataInterface {
  packages: Array<PackageSummaryInterface>
  stages: StageCollectionInterface
}

export type ActivePackageConfigurationType = {
  [key in HouseAndLandPackageOptionType]: string
}

export interface PackageSummaryInterface {
  id: string
  name: string
  storey: number
  builder: string
  configuration: ConfigurationInterface
  lots: Array<string>
}

export interface StageSummaryCollectionInterface {
  [key: string]: StageInterface
}

export interface SummaryInterface {
  packages: Array<PackageSummaryInterface>
  stages: StageSummaryCollectionInterface
}

export interface SummaryCollectionInterface {
  [key: string]: SummaryInterface
}

export interface PrecinctListItemInterface {
  id: string
  label: string
}

type MatrixDataRequestParameters = {
  projectName: string
  precinctIdOrLabel: string
  lots?: boolean
}

type PackageByIdRequestParameters = {
  projectName: string
  packageIds: string
}

type PackageByLotRequestParameters = {
  projectName: string
  lotId: string
}

type PackageOptionRequestParameters = {
  projectName: string
  packageId: string
}

type PackageSummaryRequestParameters = {
  projectName: string
  lots?: boolean
  precinctId?: string
}

type PrecinctListRequestParameters = {
  projectName: string
}

const API_VERSION = 'v3'

export const houseAndLandApi = createApi({
  reducerPath: 'houseAndLandApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getMatrixDataByPrecinct: builder.query<
      ApiResponse<MatrixDataInterface>,
      MatrixDataRequestParameters
    >({
      query: (params: MatrixDataRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages/summary`,
        method: 'get',
        params: {
          ...roomQueryParams(),
          precinctIdOrLabel: params.precinctIdOrLabel,
          lots: params.lots || false,
        },
      }),
    }),

    getPackagesById: builder.query<
      ApiResponse<Array<PackageInterface>>,
      PackageByIdRequestParameters
    >({
      query: (params: PackageByIdRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages`,
        method: 'get',
        params: {
          ...roomQueryParams(),
          packageIds: params.packageIds,
        },
      }),
    }),

    getPackagesByLot: builder.query<
      ApiResponse<Array<PackageInterface>>,
      PackageByLotRequestParameters
    >({
      query: (params: PackageByLotRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages`,
        method: 'get',
        params: {
          ...roomQueryParams(),
          lotId: params.lotId,
        },
      }),
    }),

    getPackageSummary: builder.query<
      ApiResponse<SummaryInterface>,
      PackageSummaryRequestParameters
    >({
      query: (params: PackageSummaryRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages/summary`,
        method: 'get',
        params: {
          ...roomQueryParams(),
          lots: params.lots || false,
          precinctIdOrLabel: params.precinctId || '',
          clearCache: true,
        },
      }),
    }),

    getPackageOptionsByPackage: builder.query<
      ApiResponse<PackageOption>,
      PackageOptionRequestParameters
    >({
      query: (params: PackageOptionRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/packages/${params.packageId}/options`,
        method: 'get',
        params: {
          ...roomQueryParams(),
        },
      }),
    }),
    getPrecinctList: builder.query<
      ApiResponse<Array<PrecinctListItemInterface>>,
      PrecinctListRequestParameters
    >({
      query: (params: PrecinctListRequestParameters) => ({
        url: `/${API_VERSION}/house-and-land/${params.projectName}/precincts/list`,
        method: 'get',
      }),
    }),
  }),
})

export const selectMatrixDataFromResult = ({
  data,
  isLoading,
  isError,
  isFetching,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  matrixData: MatrixDataInterface
  isLoaded: boolean
  isError: boolean
  isFetching: boolean
  errorStatus: number
  status: string
} => ({
  matrixData: data?.data || {},
  isLoaded: !isLoading,
  isError,
  isFetching,
  status,
  errorStatus: error?.status,
})

export const selectPackagesFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  packages: Array<PackageInterface>
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  packages: data?.data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const selectPackageSummaryFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
  isFetching,
}: UseQueryStateResult<any, any>): {
  packageSummary: SummaryInterface
  isLoaded: boolean
  isError: boolean
  isFetching: boolean
  errorStatus: number
  status: string
} => ({
  packageSummary: data?.data || {},
  isLoaded: !isLoading,
  isError,
  isFetching,
  status,
  errorStatus: error?.status,
})

export const selectPackageOptionsFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  packageOptions: PackageOption
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  packageOptions: data?.data || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const selectPrecinctListFromResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  precinctList: Array<PrecinctListItemInterface>
  isLoaded: boolean
  isError: boolean
  errorStatus: number
  status: string
} => ({
  precinctList: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const {
  useGetPackagesByIdQuery,
  useGetPackagesByLotQuery,
  useGetPackageSummaryQuery,
  useGetPackageOptionsByPackageQuery,
  useGetPrecinctListQuery,
  useGetMatrixDataByPrecinctQuery,
  useLazyGetMatrixDataByPrecinctQuery,
  useLazyGetPackagesByIdQuery,
} = houseAndLandApi
