import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import { SessionMap } from '@firebaseUtil/types'

import useClearCache from '@hooks/useClearCache'

import { ArrowPathSvg } from '@svg/react'

export interface ComponentProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function Refresh({ projectIdentity, session }: ComponentProps) {
  const [isRefreshing, handleRefreshClick] = useClearCache({
    projectIdentity,
    session,
  })

  return (
    <button
      type="button"
      className="cursor-hand inline-flex w-full items-center justify-center rounded-lg bg-gradient px-20 py-6 text-subHeading font-medium text-neutralColour shadow-standard"
      onClick={handleRefreshClick}
    >
      {isRefreshing ? 'Clearing cache' : 'Clear cache'}
      {isRefreshing && <ArrowPathSvg className="ml-1 h-10 w-10 animate-spin" />}
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    projectIdentity,
    session,
  })
)(Refresh)
