import React from 'react'
import { useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { removeShortlist } from '@store/actionSlices/shortlist'
import { ProjectIdentity } from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ArrowPathSvg } from '@svg/react'

interface PagePropsInterface {
  projectIdentity: ProjectIdentity
}

function Disconnect({ projectIdentity }: PagePropsInterface) {
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [disconnecting, setDisconnectState] = React.useState(false)
  const [disconnectModalVisibility, setDisconnectModalVisibility] =
    React.useState(false)

  const signOut = async () => {
    setDisconnectModalVisibility(false)
    setDisconnectState(true)
    const { sessionList } = projectIdentity
    dispatch(removeShortlist())

    await firebaseControlQuery.refreshSession()
    await Promise.all(
      sessionList.map(async ({ sessionId, projectId }) => {
        await firebaseControlQuery.updateConnection({
          connected: false,
          projectId,
          sessionId,
        })
      })
    )

    setDisconnectState(false)
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionList: [],
        sessionId: '',
        masterKey: '',
        disconnectedSessions: [],
      })
    )
  }

  return (
    <>
      <button
        type="button"
        className={`${
          projectIdentity.masterKey
            ? 'cursor-not-allowed opacity-60'
            : 'cursor-hand'
        } inline-flex w-full items-center justify-center rounded-lg bg-gradient px-20 py-6 text-subHeading font-medium text-neutralColour shadow-standard`}
        onClick={(e) =>
          projectIdentity.masterKey
            ? e.preventDefault()
            : setDisconnectModalVisibility(true)
        }
      >
        {disconnecting ? 'Disconnecting' : 'Disconnect'}
        {disconnecting && (
          <ArrowPathSvg className="ml-1 h-10 w-10 animate-spin" />
        )}
      </button>

      <div
        className={`absolute left-0 top-0 z-6 flex h-full w-full items-center justify-center transition-all duration-300 ease-in-out ${
          disconnectModalVisibility
            ? 'visible opacity-100'
            : 'invisible opacity-0'
        } `}
      >
        <div className="w-2/3 drop-shadow-2xl">
          <div className="rounded-lg bg-neutralColour p-6">
            <div className="mb-6 text-center text-heading font-medium text-mainColour">
              Are you sure you want to disconnect?
            </div>
            <div className="mb-6 text-center text-default text-mainColour">
              This will disconnect this remote from all existing sessions.
            </div>
            <div className="mt-4 flex">
              <button
                type="button"
                className="cursor-hand mr-4 w-full rounded-lg border-2 border-[#D9D9D9] bg-neutralColour py-5 text-center text-subHeading text-red-600"
                onClick={() => signOut()}
              >
                Disconnect
              </button>
              <button
                type="button"
                className="cursor-hand w-full rounded-lg bg-gradient py-5 text-center text-subHeading text-secondaryColour"
                onClick={() => setDisconnectModalVisibility(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Disconnect
