import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { TokenInterface } from '@store/actionSlices/token'
import type { ProjectIdentity, RootStateTypeExtra, User } from '@store/types'

import { hasToken } from '@utilities/token-helper'

import { SessionMap } from '@firebaseUtil/types'

export type ProtectedRouteProps = {
  redirectSessionPath: string
  authenticationPath: string
  session: SessionMap | undefined
  user: User
  projectIdentity: ProjectIdentity
  token: TokenInterface
} & RouteProps

function ProtectedRoutes({
  redirectSessionPath,
  authenticationPath,
  session,
  projectIdentity,
  token,
  ...routeProps
}: ProtectedRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true)
  const [hasSession, setHasSession] = React.useState(false)

  React.useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      if (
        connected &&
        projectIdentity.sessionId &&
        projectIdentity.sessionList.length > 0
      ) {
        setHasSession(true)
      }
    }
  }, [session, projectIdentity])

  if (isAuthenticated && !hasSession) {
    return <Route {...routeProps} />
  }

  if (isAuthenticated && hasSession) {
    return <Redirect to={{ pathname: redirectSessionPath }} />
  }

  return <Redirect to={{ pathname: authenticationPath }} />
}

export default connect(
  ({
    firestore: { session },
    projectIdentity,
    token,
    user,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    token,
    user,
  })
)(ProtectedRoutes)
