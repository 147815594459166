import React from 'react'

function InteractiveMapSkeleton() {
  return (
    <div className="h-full w-full p-5">
      <div className="flex justify-center">
        <div className="cursor-hand mt-5 h-16 w-96 rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>

      <div className="mt-5 flex h-16 justify-center gap-3">
        <div className="cursor-hand w-[36rem] rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="cursor-hand w-[36rem] rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>

      <div className="mt-5 flex flex-col gap-3">
        <div className="cursor-hand h-28 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="cursor-hand h-28 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="cursor-hand h-28 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
        <div className="cursor-hand h-28 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>
    </div>
  )
}

export default InteractiveMapSkeleton
