import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { setProjectIdentity } from '@store/actionSlices/projectIdentity'
import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

export interface BrandingProps {
  className?: string
  projectIdentity: ProjectIdentity
}

function Branding({ className, projectIdentity }: BrandingProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const isOnVisionPage = useRouteMatch('/vision')
  const [hasLogo, setLogoState] = React.useState(true)

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    configData,
    isLoaded: isConfigLoaded,
    isError: configHasError,
  } = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectFromConfigResult,
      skip: !projectIdentity.projectId,
    }
  )

  const onLogoClick = async () => {
    history.push('vision')
    await firebaseControlQuery.updateRoute('vision')
  }

  const onImageError = () => {
    setLogoState(false)
  }

  React.useEffect(() => {
    if (isConfigLoaded && !configHasError) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          ...configData,
        })
      )
    }
  }, [isConfigLoaded])

  const logoSrc = React.useMemo(() => {
    const {
      logo: { src },
      theme: { remoteVisionLogo },
    } = projectIdentity

    if (remoteVisionLogo && isOnVisionPage) {
      return remoteVisionLogo
    }

    return src
  }, [projectIdentity, isOnVisionPage])

  return (
    <div className={className}>
      <button type="button" onClick={onLogoClick}>
        {(!isOnVisionPage || logoSrc) &&
          (hasLogo ? (
            <img
              src={logoSrc}
              alt="Logo"
              className="max-h-[85px] w-full"
              onError={onImageError}
            />
          ) : (
            <span className="text-4xl font-bold">
              {projectIdentity.projectLabel}
            </span>
          ))}
      </button>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(Branding)
