import { Dot } from 'pure-react-carousel'
import React from 'react'

import { HorizontalScrollContainerRef, TabInterface } from '@store/types'

import HorizontalScrollContainer from '@components/horizontal-scroll-container'

export interface ComponentPropsInterface {
  tabs: Array<TabInterface>
  activeGalleryTabIndex: number
  onTabChange: (arg: number) => void
  tabWidth?: number
  className?: string
}

function GalleryTab({
  tabs,
  activeGalleryTabIndex,
  onTabChange,
  tabWidth,
  className = '',
}: ComponentPropsInterface) {
  const horizontalScrollWrapperRef =
    React.useRef<HorizontalScrollContainerRef>()

  const handleClick = (tab: TabInterface) => {
    if (
      horizontalScrollWrapperRef.current &&
      horizontalScrollWrapperRef.current.isDragging()
    ) {
      return
    }
    onTabChange(tab.index)
  }

  return (
    <HorizontalScrollContainer
      className={`${className} scroll-container-${activeGalleryTabIndex}`}
      ref={horizontalScrollWrapperRef}
    >
      {tabs.map((tab: TabInterface, i) => (
        <div className="flex last:mr-5" key={tab.index}>
          <span className={`${i === 0 ? 'w-5' : 'w-[15px]'}`}></span>
          <Dot
            key={`gallery-tab-${tab.index}`}
            className={`${
              activeGalleryTabIndex === tab.index
                ? `active-tab-${activeGalleryTabIndex} selected-gallery-tab cursor-default bg-gradient text-neutralColour`
                : 'cursor-pointer bg-neutralColour text-tertiaryColour'
            } shrink-0 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg px-[12px] py-[18px] text-subHeading font-medium shadow-standard`}
            onClick={() => handleClick(tab)}
            style={{ width: tabWidth || 503 }}
            slide={activeGalleryTabIndex}
            disabled={false}
            title={tab.label}
          >
            <span>{tab.label}</span>
          </Dot>
        </div>
      ))}
    </HorizontalScrollContainer>
  )
}

export default GalleryTab
