import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { removeShortlist } from '@store/actionSlices/shortlist'
import type { RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Navigation from '@components/navigation'

import { selectFromResult, useGetVisionQuery } from '@api/vision'

import { SessionMap } from '@firebaseUtil/types'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

import VisionSkeleton from './vision-skeleton'

enum BackgroundType {
  Color = 'color',
  Image = 'image',
  None = 'none',
}

interface VisionProps {
  projectName: string
  backgroundType: BackgroundType
  backgroundImageSource?: string
  backgroundColor?: string
  session: SessionMap | undefined
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

function Vision({
  projectName,
  backgroundType,
  backgroundImageSource,
  backgroundColor,
  session,
}: VisionProps) {
  const dispatch = useDispatch()

  const visionPayload = useGetVisionQuery({ projectName }, { selectFromResult })

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      if (!connected) {
        dispatch(removeShortlist())
      }
    }
  }, [session])

  const imageSource = React.useMemo(() => {
    if (backgroundType === BackgroundType.Image) {
      return backgroundImageSource
        ? AssetHandler({
            url: backgroundImageSource,
            type: 'new',
          })
        : PLACEHOLDER_IMAGE
    }

    const { gallery = [] } = visionPayload

    if (gallery.length === 0) {
      return PLACEHOLDER_IMAGE
    }

    const [galleryItem] = gallery

    if (galleryItem?.imageSource === '') {
      return PLACEHOLDER_IMAGE
    }

    return AssetHandler({
      url: galleryItem.imageSource,
      type: 'legacy',
    })
  }, [backgroundType, backgroundImageSource, visionPayload])

  return (
    <Container hasBottomNavigation>
      <Navigation type="bottom-navigation">
        <DataHandler
          payload={{
            ...visionPayload,
            data: visionPayload.gallery,
          }}
          skeletonFrame={<VisionSkeleton />}
        >
          <div className="absolute h-full w-full overflow-hidden">
            {backgroundType === BackgroundType.Color ? (
              <div
                className="h-full w-full"
                style={{ backgroundColor: backgroundColor || '#FFFFFF' }}
              ></div>
            ) : (
              <img
                className="h-full w-full object-cover"
                src={imageSource}
                alt="Background"
              />
            )}
          </div>
        </DataHandler>
      </Navigation>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity: {
    projectId,
    theme: {
      remoteVisionBackground: { type, src, color },
    },
  },
  shortlist,
}: RootStateTypeExtra) => ({
  session,
  projectName: projectId,
  backgroundType: type as BackgroundType,
  backgroundImageSource: src,
  backgroundColor: color,
  shortlist,
})

export default connect(mapStateToProps)(Vision)
