import React from 'react'

function NoLotCard() {
  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-secondaryColour px-[30px] py-9 text-heading font-medium text-neutralColour">
      No Lots Found
    </div>
  )
}

export default NoLotCard
