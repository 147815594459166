import React from 'react'

function PanoramicSkeleton() {
  return (
    <div className="absolute h-full w-full overflow-hidden bg-gray-700/30">
      <div className="shimmer-container relative h-full"></div>
    </div>
  )
}

export default PanoramicSkeleton
