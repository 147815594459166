import { deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import React from 'react'

import firestore from './firebase-db'
import type { Channel, SessionMap, SessionOwner } from './types'

interface WildCardObjectInterface {
  [key: string]: any
}

interface FirebaseControlQueryProps {
  projectId: string
  sessionKey?: string
  lightKey?: string
}

interface DoesLightMapExist {
  projectId: string
  lightId: string
}

interface DoesSessionExistParams {
  projectId: string
  sessionId: string
}

interface CreateSessionParams {
  projectId: string
  sessionId: string
}

interface UpdateSessionParams {
  connected: boolean
  projectId: string
  sessionId: string
}

interface ShortlistUpdatePayload {
  key: string
  payload: Record<string, any>
}

const FirebaseControlQuery = ({
  projectId,
  sessionKey = '',
  lightKey = '',
}: FirebaseControlQueryProps) => {
  const [currentSessionKey, setCurrentSessionKey] = React.useState(sessionKey)
  const [currentProjectId, setCurrentProjectId] = React.useState(projectId)

  const doesProjectExist = async (projectName: string) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Unable to check project existence.'
      )
      return false
    }

    try {
      const docRef = doc(firestore, 'devsuite', projectName)
      const docSnap = await getDoc(docRef)
      return docSnap.exists()
    } catch (error) {
      console.error(`Error checking if project: ${projectName} exist.`, error)
      return false
    }
  }

  const isProjectEnabled = async (projectName: string) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Unable to check project status.'
      )
      return false
    }

    try {
      const docRef = doc(firestore, 'devsuite', projectName)
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        console.info(`Project '${projectName}' does not exist.`)
        return false
      }

      const data = docSnap.data()

      if (!data) {
        return true
      }

      if (!('enable' in data)) {
        return true
      }

      return data?.enable ?? false
    } catch (error) {
      console.error(
        `Error checking if project '${projectName}' is enabled:`,
        error
      )
      return false
    }
  }

  const getMap = async () => {
    if (!firestore) {
      console.error('Firestore is not initialized. Unable to retrieve map.')
      return undefined
    }

    try {
      const docRef = doc(firestore, 'session-base', 'map')
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        console.info('Map document not found in Firestore.')
        return undefined
      }

      return docSnap.data() as SessionMap
    } catch (error) {
      console.error('Error fetching map document from Firestore:', error)
      return undefined
    }
  }

  const doesLightMapExist = async ({
    projectId,
    lightId,
  }: DoesLightMapExist) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Unable to check light map existence.'
      )
      return false
    }

    try {
      const docRef = doc(firestore, 'devsuite', projectId, 'light-map', lightId)
      const docSnap = await getDoc(docRef)
      return docSnap.exists()
    } catch (error) {
      console.error(
        `Error checking if light maps '${lightId}' exists for project '${projectId}'.`,
        error
      )
      return false
    }
  }

  const doesSessionExist = async ({
    projectId,
    sessionId,
  }: DoesSessionExistParams) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Unable to check session existence.'
      )
      return false
    }

    try {
      const docRef = doc(firestore, 'devsuite', projectId, 'session', sessionId)
      const docSnap = await getDoc(docRef)

      return docSnap.exists()
    } catch (error) {
      console.error(
        `Error checking if session '${sessionId}' exists for project '${projectId}'.`,
        error
      )
      return false
    }
  }

  const createSession = async ({
    projectId,
    sessionId,
  }: CreateSessionParams) => {
    if (!firestore) {
      console.error('Firestore is not initialized. Skipping creating session.')
      return false
    }

    if (!projectId || !sessionId) {
      console.error(
        'Missing projectId or sessionId. Skipping session creation.'
      )
      return false
    }

    try {
      const sessionExist = await doesSessionExist({ projectId, sessionId })
      const map = await getMap()

      if (sessionExist || !map) {
        return false
      }

      const docRef = doc(firestore, 'devsuite', projectId, 'session', sessionId)

      await setDoc(docRef, map)

      return true
    } catch (error) {
      console.error(
        `Failed to create session for project '${projectId}' and session '${sessionId}'.`,
        error
      )
      return false
    }
  }

  const updateConnection = async ({
    connected,
    projectId,
    sessionId,
  }: UpdateSessionParams) => {
    if (!firestore) {
      console.error('Firestore is not initialized. Skipping connection update.')
      return
    }

    if (!sessionId) {
      console.error('Session ID is not provided. Skipping connection update.')
      return
    }

    try {
      const sessionExist = await doesSessionExist({
        projectId,
        sessionId,
      })

      if (!sessionExist) {
        console.error(
          `Session does not exist for project '${projectId}' and session '${sessionId}'.`
        )
        return
      }

      const docRef = doc(firestore, 'devsuite', projectId, 'session', sessionId)

      await updateDoc(docRef, {
        connected,
      })
    } catch (error) {
      console.error(
        `Failed to update connection status for session '${sessionId}' in project '${projectId}'.`,
        error
      )
    }
  }

  const refreshSession = async () => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping refreshing session.'
      )
      return
    }

    if (!currentSessionKey) {
      console.error('Session key is not defined. Skipping refresh session.')
      return
    }

    try {
      const map = await getMap()

      if (!map) {
        console.error('Failed to retrieve map. Skipping refresh.')
        return
      }

      const sessionExist = await doesSessionExist({
        projectId: currentProjectId,
        sessionId: currentSessionKey,
      })

      if (!sessionExist) {
        console.error(
          `Session does not exist for project '${currentProjectId}' and session '${currentSessionKey}'.`
        )
        return
      }

      const docRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'session',
        currentSessionKey
      )

      await updateDoc(docRef, {
        ...map,
      })
    } catch (error) {
      console.error(
        `Failed to refresh session for project '${currentProjectId}' and session '${currentSessionKey}'.`,
        error
      )
    }
  }

  const deleteSession = async () => {
    if (!firestore) {
      console.error('Firestore is not initialized. Skipping deleting session.')
      return
    }

    if (!currentSessionKey) {
      console.error('Session key is not defined. Skipping update.')
      return
    }

    try {
      await deleteDoc(
        doc(
          firestore,
          'devsuite',
          currentProjectId,
          'session',
          currentSessionKey
        )
      )
    } catch (error) {
      console.error(
        `Failed to delete for project '${currentProjectId}' and session '${currentSessionKey}'.`,
        error
      )
    }
  }

  const update = async (obj: WildCardObjectInterface) => {
    if (!firestore) {
      console.error('Firestore is not initialized. Skipping update.')
      return
    }

    if (!currentSessionKey) {
      console.error('Session key is not defined. Skipping update.')
      return
    }

    try {
      const sessionExist = await doesSessionExist({
        projectId: currentProjectId,
        sessionId: currentSessionKey,
      })

      if (!sessionExist) {
        console.warn(
          `Session does not exist for project '${currentProjectId}' and session '${currentSessionKey}'.`
        )
        return
      }

      const docRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'session',
        currentSessionKey
      )

      await updateDoc(docRef, obj)
    } catch (error) {
      console.error(
        `Failed to update for project '${currentProjectId}' and session '${currentSessionKey}'.`,
        error
      )
    }
  }

  const updateLightChannel = async (obj: WildCardObjectInterface) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping updating light channel.'
      )
      return
    }

    if (!lightKey) {
      console.error('Light key is not defined. Skipping update.')
      return
    }

    try {
      const lightMapExist = await doesLightMapExist({
        projectId: currentProjectId,
        lightId: lightKey as string,
      })

      if (!lightMapExist) {
        console.error(
          `Light map for project '${currentProjectId}' and light '${lightKey}' does not exist.`
        )
        return
      }

      const docRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'light-map',
        lightKey
      )

      await updateDoc(docRef, {
        obj,
      })
    } catch (error) {
      console.error(
        `Failed to update channels in light map for project '${currentProjectId}' and light '${lightKey}'.`,
        error
      )
    }
  }

  const updateLightMap = async (channels: Array<Channel>) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping updating light map.'
      )
      return
    }

    if (!lightKey) {
      console.error('Light key is not defined. Skipping update.')
      return
    }

    try {
      const lightMapExist = await doesLightMapExist({
        projectId: currentProjectId,
        lightId: lightKey as string,
      })

      if (!lightMapExist) {
        console.error(
          `Light map for project '${currentProjectId}' and light '${lightKey}' does not exist.`
        )
        return
      }

      const docRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'light-map',
        lightKey
      )

      await updateDoc(docRef, {
        channels,
      })
    } catch (error) {
      console.error(
        `Failed to update light map for project '${currentProjectId}' and light '${lightKey}'.`,
        error
      )
    }
  }

  const updateFilterStateInLightMap = async (filteredApplied: boolean) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping updating filter state in light map.'
      )
      return
    }

    if (!lightKey) {
      console.error('Light key is not defined. Skipping update.')
      return
    }

    try {
      const lightMapExist = await doesLightMapExist({
        projectId: currentProjectId,
        lightId: lightKey as string,
      })

      if (!lightMapExist) {
        console.error(
          `Light map for project '${currentProjectId}' and light '${lightKey}' does not exist.`
        )
        return
      }

      const docRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'light-map',
        lightKey
      )

      await updateDoc(docRef, {
        hasFilter: filteredApplied,
      })
    } catch (error) {
      console.error(
        `Failed to update filter state in light map for project '${currentProjectId}' and light '${lightKey}'.`,
        error
      )
    }
  }

  const updateShortlist = async (value: ShortlistUpdatePayload) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping updating shortlist.'
      )
      return
    }

    try {
      const docRef = doc(firestore, 'shortlisted-property', value.key)
      const docSnap = await getDoc(docRef)

      const docPayload = {
        payload: JSON.stringify(value.payload),
        createdAt: Date.now(),
      }

      if (!docSnap.exists()) {
        await setDoc(docRef, docPayload)
      } else {
        await updateDoc(docRef, docPayload)
      }
    } catch (error) {
      console.error(`Failed to update shortlist for key '${value.key}'.`, error)
    }
  }

  const updateRoute = async (activeRoute: string) => {
    if (!firestore) {
      console.error('Firestore is not initialized. Skipping updating route.')
      return
    }

    if (!currentSessionKey) {
      console.error('No session key available')
      return
    }

    try {
      const sessionExists = await doesSessionExist({
        projectId: currentProjectId,
        sessionId: currentSessionKey,
      })

      if (!sessionExists) {
        console.error(`Session with ID ${currentSessionKey} does not exist.`)
        return
      }

      const sessionDocRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'session',
        currentSessionKey
      )

      await updateDoc(sessionDocRef, { activeRoute })
    } catch (error) {
      console.error('Failed to update route:', error)
    }
  }

  const updateSessionOwner = async (sessionOwners: Array<SessionOwner>) => {
    if (!firestore) {
      console.error(
        'Firestore is not initialized. Skipping updating session owner.'
      )
      return
    }

    if (!currentSessionKey) {
      console.error('No session key available')
      return
    }

    try {
      const sessionExists = await doesSessionExist({
        projectId: currentProjectId,
        sessionId: currentSessionKey,
      })

      if (!sessionExists) {
        console.error(`Session with ID ${currentSessionKey} does not exist.`)
        return
      }

      const sessionDocRef = doc(
        firestore,
        'devsuite',
        currentProjectId,
        'session',
        currentSessionKey
      )

      await updateDoc(sessionDocRef, { sessionOwners })
    } catch (error) {
      console.error('Failed to update session owner:', error)
    }
  }

  React.useEffect(() => {
    if (sessionKey) {
      setCurrentSessionKey(sessionKey)
    }
  }, [sessionKey])

  React.useEffect(() => {
    if (projectId) {
      setCurrentProjectId(projectId)
    }
  }, [projectId])

  return {
    update,
    getMap,
    deleteSession,
    createSession,
    refreshSession,
    doesSessionExist,
    updateLightMap,
    updateShortlist,
    updateConnection,
    isProjectEnabled,
    doesProjectExist,
    updateLightChannel,
    updateFilterStateInLightMap,
    updateRoute,
    updateSessionOwner,
  }
}

export default FirebaseControlQuery
