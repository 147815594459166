import React from 'react'

import { ArrowSvg, EyeCloseSvg, EyeSvg } from '@components/adgroup-svg/react'

interface InteractiveMapLayerProps {
  label?: string
  isActive?: boolean
  isValid?: boolean
  path?: string
  onClick: () => void
  onClickLink: React.MouseEventHandler<HTMLAnchorElement>
}

function InteractiveMapLayer({
  label,
  isActive,
  isValid,
  path,
  onClick,
  onClickLink,
}: InteractiveMapLayerProps) {
  return (
    <button
      type="button"
      className={`flex cursor-pointer items-center justify-between rounded-lg bg-secondaryColour p-5 text-mainColour transition-all duration-300 ${
        isActive ? 'border-l-8 border-mainColour' : ''
      }`}
      onClick={onClick}
    >
      {isValid ? (
        <a href={path} onClick={onClickLink}>
          <span className="border-b-2 border-mainColour text-default font-medium">
            {label}
          </span>
          <span className="border-b-2 border-mainColour text-default font-medium">
            <ArrowSvg
              size="m"
              className="-mr-2.5 inline-block h-10 w-10 stroke-mainColour"
              styles={{ rotate: '135deg' }}
            />
          </span>
        </a>
      ) : (
        <h2 className="text-default font-medium">{label}</h2>
      )}
      <span
        className={`rounded-full ${
          isActive ? 'bg-mainColour' : 'bg-neutralColour'
        } px-2 py-2 transition-all duration-300`}
      >
        {isActive ? (
          <EyeCloseSvg className="h-7 w-7 text-neutralColour" />
        ) : (
          <EyeSvg className="h-7 w-7 text-mainColour" />
        )}
      </span>
    </button>
  )
}

export default InteractiveMapLayer
