import React from 'react'
import { connect } from 'react-redux'

import { ShortlistState } from '@store/actionSlices/shortlist'
import {
  ProjectIdentity,
  RootStateTypeExtra,
  ShortlistPropertyType,
  UnitShortlistInterface,
} from '@store/types'

import { Modal } from '@components/modals'

import { numberWithCommas } from '@utilities/helper'
import ShortlistHandler from '@utilities/shortlist-handler'

import { StarSvg } from '@svg/react'

import { Unit } from '@adUtilities/types/apartment'

export interface ComponentProps {
  unit: Unit
  shortlist: ShortlistState
  showPrice: boolean
  hideSoldPrice: boolean
  availableStatusLabel: string
  setShortlistErrorMessage: (arrg: string) => void
  trigger?: (unit: Unit) => void
  hideUnitStatus?: boolean
  disableSoldUnit?: boolean
  projectIdentity: ProjectIdentity
}

function UnitCard({
  unit,
  shortlist,
  showPrice,
  hideSoldPrice,
  availableStatusLabel,
  trigger,
  setShortlistErrorMessage,
  hideUnitStatus,
  projectIdentity,
  disableSoldUnit,
}: ComponentProps) {
  const [showConfirmModal, toggleConfirmModal] = React.useState(false)
  const [removeShortlistProperty, setRemoveShortlistProperty] =
    React.useState(false)
  const property = React.useMemo<UnitShortlistInterface>(
    () => ({
      propertyId: unit.id,
      propertyName: unit.name,
      references: {
        blockId: unit.blockId,
        levelId: unit.metas.level,
      },
      type: ShortlistPropertyType.Unit,
    }),
    [unit]
  )

  const [isShortlisted, handleShortlistClick] =
    (property &&
      ShortlistHandler({
        property,
        shortlist,
        setShortlistErrorMessage,
        projectIdentity,
        toggleConfirmModal,
        removeShortlistProperty,
        setRemoveShortlistProperty,
        available: unit.metas.status,
      })) ||
    []

  const isSoldUnit = React.useMemo(() => {
    if (unit.metas.status !== availableStatusLabel) {
      return true
    }
    return false
  }, [unit])

  const canWeShowPrice =
    showPrice && !(unit?.metas.status !== availableStatusLabel && hideSoldPrice)

  return (
    <>
      <div
        className={`${isSoldUnit ? 'opacity-60' : ''} ${
          isSoldUnit && disableSoldUnit ? 'pointer-events-none' : ''
        } relative w-full cursor-pointer rounded-lg bg-gradient p-5 text-neutralColour shadow-standard`}
      >
        <div
          onClick={() => trigger && trigger(unit)}
          role="none"
          className="w-full"
        >
          <div className="mb-4 flex items-center justify-between">
            <div className="truncate pr-8 text-heading font-medium leading-none">
              {`${unit.metas.level}-${unit.name}`}
            </div>

            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleShortlistClick?.()
              }}
            >
              {unit.metas.status === availableStatusLabel ? (
                <StarSvg
                  size="m"
                  className={`h-8 w-8 transition-all ${
                    isShortlisted
                      ? 'stroke-amber-500 text-amber-500'
                      : 'stroke-neutralColour text-transparent'
                  }`}
                  stroke={isShortlisted ? 0 : 2}
                />
              ) : (
                <StarSvg
                  size="m"
                  className="h-8 w-8 stroke-neutralColour text-transparent"
                  stroke={2}
                />
              )}
            </button>
          </div>
          <div className="flex items-center justify-between text-default">
            <span>
              {Number(unit?.metas?.price) > 0 &&
                canWeShowPrice &&
                `$${numberWithCommas(String(unit?.metas?.price))}`}
            </span>
            {!hideUnitStatus && <span>{unit.metas.statusName}</span>}
          </div>
        </div>
      </div>

      <Modal
        isVisible={showConfirmModal}
        toggleModal={toggleConfirmModal}
        message="Do you want to remove this item from the shortlist?"
        modalWidth="max-w-lg"
        title="Confirmation"
        applyButtonText="Yes"
        closeButtonText="No"
        handleApply={() => {
          setRemoveShortlistProperty(true)
        }}
      />
    </>
  )
}

export default connect(
  ({ projectIdentity, shortlist }: RootStateTypeExtra) => ({
    projectIdentity,
    shortlist,
  })
)(UnitCard)
