import React from 'react'

import { SvgProps } from './types'

function VolumeMinusSvg({
  width,
  height,
  className,
  size,
  styles,
  stroke,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '38'}
      height={height || '32'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 38 32"
      stroke="currentColor"
    >
      <path
        d="M33.125 8.125C33.125 8.125 35.75 10.75 35.75 15.125C35.75 19.5 33.125 22.125 33.125 22.125"
        stroke="currentColor"
        strokeWidth={stroke || '3.25'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 19.2497V12.7502C2.5 11.822 2.86875 10.9317 3.52513 10.2753C4.1815 9.61896 5.07174 9.25021 6 9.25021H11.075C11.4172 9.25013 11.7518 9.14974 12.0375 8.96146L22.5375 2.03846C22.8013 1.86472 23.1072 1.76563 23.4228 1.7517C23.7384 1.73777 24.0519 1.80952 24.33 1.95934C24.6081 2.10915 24.8404 2.33145 25.0024 2.60264C25.1644 2.87384 25.2499 3.18382 25.25 3.49971V28.5002C25.2499 28.8161 25.1644 29.1261 25.0024 29.3973C24.8404 29.6685 24.6081 29.8908 24.33 30.0406C24.0519 30.1904 23.7384 30.2622 23.4228 30.2482C23.1072 30.2343 22.8013 30.1352 22.5375 29.9615L12.0375 23.0385C11.7518 22.8502 11.4172 22.7498 11.075 22.7497H6C5.07174 22.7497 4.1815 22.381 3.52513 21.7246C2.86875 21.0682 2.5 20.178 2.5 19.2497Z"
        stroke="currentColor"
        strokeWidth={stroke || '3.25'}
      />
    </svg>
  )
}

export default VolumeMinusSvg
