import React from 'react'

import { SvgProps } from './types'

function CloseCircleSvg({
  width,
  height,
  className,
  size,
  styles,
  fill,
}: SvgProps) {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.6,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '31'}
      height={height || '30'}
      viewBox="0 0 31 30"
      fill={fill || '#fff'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.6066 4.39214C24.215 2.99755 22.5617 1.89163 20.7415 1.13789C18.9213 0.384154 16.9701 -0.00254274 15 1.2582e-05C6.71569 1.2582e-05 0 6.7157 0 15C0 19.1422 1.67986 22.8931 4.39463 25.6079C5.78618 27.0024 7.43954 28.1084 9.25975 28.8621C11.08 29.6158 13.0311 30.0025 15.0012 30C23.2855 30 30.0012 23.2843 30.0012 15C30.0012 10.8578 28.3214 7.10691 25.6066 4.39214ZM23.6793 23.6743C22.5409 24.8156 21.1882 25.7207 19.6989 26.3376C18.2097 26.9545 16.6132 27.271 15.0012 27.269C8.22306 27.269 2.72852 21.7744 2.72852 14.9963C2.7265 13.3843 3.04303 11.7878 3.65993 10.2985C4.27682 8.80928 5.18192 7.4566 6.32322 6.31823C7.46132 5.17692 8.81377 4.2718 10.3028 3.65489C11.7919 3.03799 13.3882 2.72147 15 2.72353C21.7769 2.72353 27.2715 8.21807 27.2715 14.995C27.2736 16.6068 26.9571 18.2031 26.3402 19.6922C25.7233 21.1813 24.8181 22.5337 23.6768 23.6718L23.6793 23.6743Z" />
      <path d="M16.6372 14.9639L20.7348 10.8422C20.9369 10.6205 21.0461 10.329 21.0396 10.0283C21.0332 9.72761 20.9115 9.44106 20.7 9.22847C20.4885 9.01588 20.2036 8.89366 19.9046 8.88734C19.6057 8.88101 19.3159 8.99107 19.0957 9.19453L19.0968 9.19346L14.9992 13.3152L10.9016 9.19346C10.6813 8.99014 10.3914 8.88028 10.0925 8.88679C9.79355 8.89331 9.50869 9.01571 9.29734 9.22845C9.08599 9.44118 8.96449 9.7278 8.9582 10.0285C8.95192 10.3292 9.06133 10.6207 9.2636 10.8422L9.26253 10.8411L13.3601 14.9629L9.26253 19.0846C9.14724 19.191 9.05455 19.3198 8.99003 19.4631C8.92551 19.6065 8.8905 19.7615 8.88711 19.9189C8.88372 20.0762 8.91202 20.2326 8.9703 20.3787C9.02858 20.5247 9.11564 20.6574 9.22623 20.7687C9.33683 20.8801 9.46867 20.9677 9.61383 21.0264C9.75899 21.0851 9.91447 21.1137 10.0709 21.1104C10.2273 21.1071 10.3814 21.072 10.524 21.0072C10.6666 20.9424 10.7947 20.8492 10.9005 20.7333L10.9016 20.7322L14.9992 16.6105L19.0968 20.7322C19.2026 20.8482 19.3306 20.9415 19.4731 21.0064C19.6156 21.0712 19.7698 21.1065 19.9262 21.1099C20.0826 21.1133 20.2381 21.0848 20.3833 21.0262C20.5285 20.9676 20.6604 20.88 20.7711 20.7688C20.8817 20.6575 20.9689 20.5249 21.0272 20.3789C21.0856 20.2329 21.114 20.0765 21.1107 19.9191C21.1074 19.7618 21.0725 19.6067 21.0081 19.4633C20.9437 19.3199 20.8511 19.1911 20.7358 19.0846L20.7348 19.0835L16.6372 14.9639Z" />
    </svg>
  )
}

export default CloseCircleSvg
