import { getFirebaseHost, getFirebasePort } from '@utilities/helper'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
}

export const getFirebaseLocalHost = () => {
  const firebasePort = getFirebasePort()
  const firebaseHost = getFirebaseHost()
  return {
    firebaseHost,
    firebasePort,
  }
}

export default firebaseConfig
