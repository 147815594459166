import React from 'react'
import { connect } from 'react-redux'

import {
  PlayerState,
  RootStateTypeExtra,
  ThemeConfigInterface,
} from '@store/types'

import { SimplifiedThemeConfig } from '@api/config'

import styleUtil from '@utilities/style-util'

import {
  LoopOneSvg,
  LoopSvg,
  PauseSvg,
  PlaySvg,
  SpeakerSvg,
  SpeakerXMarkSvg,
  StopSvg,
  VolumeAddSvg,
  VolumeMinusSvg,
} from '@svg/react'

import ScrubBar from './scrub-bar'

interface ComponentProps {
  scrubbarTimerState: boolean
  isBuffering: boolean
  playerState: PlayerState
  isLooping: boolean
  isMuted: boolean
  volume: number
  videoLengthInSeconds: number
  currentVideoPositionInSeconds: number
  isSeeking?: boolean
  showLoopOption?: boolean
  handleStopClick: () => void
  handlePauseClick: () => void
  handlePlayClick: () => void
  handleMuteClick: (arg: boolean) => void
  handleVolumeDownClick: () => void
  handleVolumeUpClick: () => void
  handleSeekTo: (argInSeconds: number) => void
  handleLoopClick?: (arg: boolean) => void
  theme: ThemeConfigInterface
}

function VideoPlayer({
  scrubbarTimerState,
  isBuffering,
  playerState,
  isLooping,
  isMuted,
  volume,
  videoLengthInSeconds,
  currentVideoPositionInSeconds,
  isSeeking = false,
  showLoopOption = true,
  handleStopClick,
  handlePauseClick,
  handlePlayClick,
  handleMuteClick,
  handleVolumeDownClick,
  handleVolumeUpClick,
  handleSeekTo,
  handleLoopClick,
  theme,
}: ComponentProps) {
  const themeConfig: SimplifiedThemeConfig = styleUtil(theme)

  return (
    <div className="rounded-lg bg-secondaryColour px-4 py-6">
      <div className="flex items-center space-x-3">
        {playerState === PlayerState.Pause ? (
          <button type="button" onClick={handlePlayClick}>
            <PlaySvg className="h-5 w-5 cursor-pointer text-mainColour" />
          </button>
        ) : (
          <button type="button" onClick={handlePauseClick}>
            <PauseSvg
              className="h-5 w-5 cursor-pointer text-mainColour"
              stroke={1.2}
            />
          </button>
        )}

        <button type="button" onClick={handleStopClick}>
          <StopSvg
            className="h-9 w-9 cursor-pointer text-mainColour"
            stroke={1.2}
          />
        </button>

        {showLoopOption && handleLoopClick && (
          <button type="button" onClick={() => handleLoopClick(!isLooping)}>
            {isLooping ? (
              <LoopOneSvg className="h-7 w-7 cursor-pointer text-mainColour" />
            ) : (
              <LoopSvg className="h-7 w-7 cursor-pointer text-mainColour" />
            )}
          </button>
        )}

        <ScrubBar
          scrubbarTimerState={scrubbarTimerState}
          isBuffering={isBuffering}
          playerState={playerState}
          videoLengthInMilliseconds={videoLengthInSeconds * 1000}
          currentVideoPositionInMilliseconds={
            currentVideoPositionInSeconds * 1000
          }
          isSeeking={isSeeking}
          themeConfig={themeConfig}
          handleSeekTo={handleSeekTo}
        />

        <button type="button" onClick={() => handleMuteClick(!isMuted)}>
          {isMuted ? (
            <SpeakerXMarkSvg
              stroke={3}
              className="h-6 w-6 cursor-pointer text-mainColour"
            />
          ) : (
            <SpeakerSvg
              stroke={3}
              className="h-4 w-4 cursor-pointer text-mainColour"
            />
          )}
        </button>

        <button type="button" onClick={handleVolumeDownClick}>
          <VolumeMinusSvg
            stroke={3}
            className={`h-6 w-6 cursor-pointer ${
              volume < 1 ? 'text-gray-300' : 'text-mainColour'
            }`}
          />
        </button>

        <button type="button" onClick={handleVolumeUpClick}>
          <VolumeAddSvg
            stroke={3}
            className={`h-7 w-7 cursor-pointer ${
              volume >= 10 ? 'text-gray-300' : 'text-mainColour'
            }`}
          />
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  projectIdentity: { theme },
}: RootStateTypeExtra) => ({
  theme,
})

export default connect(mapStateToProps)(VideoPlayer)
