import React from 'react'

function PageNotFound() {
  return (
    <div className="flex h-full w-full items-center justify-center bg-[#171A21]">
      <div className="rounded-full bg-secondaryColour p-16 text-title">
        404 Page Not Found
      </div>
    </div>
  )
}

export default PageNotFound
