import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import GalleryItem from '@src/components/gallery-item'
import GalleryTab from '@src/components/gallery-tab'

import {
  GalleryItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GallerySkeleton from '@components/gallery-skeleton'

import {
  selectFromResult as selectFromBrochureResult,
  useGetBrochureQuery,
} from '@api/brochure'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function Brochure({ session, projectIdentity }: PagePropsInterface) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)
  const [transitionEffectClass, setTransitionEffectClass] = React.useState('')

  const brochurePayload = useGetBrochureQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromBrochureResult }
  )

  const tabItems: Array<string> = React.useMemo(() => {
    const { brochureData = [] } = brochurePayload

    if (brochureData.length < 1) {
      return []
    }

    return brochureData.map((item) => item.label || '')
  }, [brochurePayload])

  const galleryItems: Array<GalleryItemInterface> = React.useMemo(() => {
    const { brochureData = [] } = brochurePayload

    if (brochureData.length < 1) {
      return []
    }

    return brochureData[activeTabIndex].items || []
  }, [brochurePayload, activeTabIndex])

  const handleOnTabChange = React.useCallback(
    async (index: number) => {
      setActiveTabIndex(index)

      setTransitionEffectClass(
        index > activeTabIndex
          ? 'transition-slide-left'
          : 'transition-slide-right'
      )

      setActiveSlideIndex(0)
      await firebaseControlQuery.update({
        'brochure.galleryControlV2.activeTabIndex': index,
        'brochure.galleryControlV2.activeSlideIndex': 0,
      })
    },
    [activeTabIndex]
  )

  const handleOnItemClick = React.useCallback(
    async (event: MouseEvent<HTMLDivElement>, index: number) => {
      event.preventDefault()
      setActiveSlideIndex(index)
      await firebaseControlQuery.update({
        'brochure.galleryControlV2.activeSlideIndex': index,
      })
    },
    []
  )

  React.useEffect(() => {
    if (!session) {
      return
    }

    const {
      brochure: {
        galleryControlV2: {
          activeTabIndex: activeTabIndexFirebase,
          activeSlideIndex: activeSlideIndexFirebase,
        },
      },
    } = session

    setActiveTabIndex(activeTabIndexFirebase)
    setActiveSlideIndex(activeSlideIndexFirebase)
  }, [session?.brochure.galleryControlV2])

  return (
    <Container>
      <DataHandler
        payload={{ ...brochurePayload, data: brochurePayload.brochureData }}
        skeletonFrame={<GallerySkeleton hasTabBar />}
      >
        <div className="h-full w-full">
          <div className="flex h-top-bar w-full items-center justify-center px-5 text-neutralColour">
            <div className="text-title font-medium">Brochure</div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            <GalleryTab
              items={tabItems}
              activeGalleryTabIndex={activeTabIndex}
              onTabChange={handleOnTabChange}
              className="mb-[15px] flex-shrink-0"
            />

            <TransitionGroup className="h-full w-full">
              <CSSTransition
                key={activeTabIndex}
                classNames={transitionEffectClass}
                timeout={500}
                exit={false}
                onEntered={(node: HTMLElement) =>
                  node.classList.remove('transition-slide-left-enter-done')
                }
              >
                <div className="grid grid-cols-3 gap-4 px-4">
                  {galleryItems.map((item, index) => (
                    <GalleryItem
                      key={index as number}
                      item={item}
                      isActive={index === activeSlideIndex}
                      onItemClick={(event: MouseEvent<HTMLDivElement>) =>
                        handleOnItemClick(event, index)
                      }
                    />
                  ))}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

const mapStateToProps = ({
  firestore: { session },
  projectIdentity,
}: RootStateTypeExtra) => ({
  session,
  projectIdentity,
})

export default connect(mapStateToProps)(Brochure)
