import React from 'react'

function ShortlistSkeleton() {
  return (
    <div className="h-full w-full p-5">
      <div className="flex py-4">
        <div className="cursor-hand h-16 w-96 rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
          <div className="shimmer-container relative h-full opacity-50"></div>
        </div>
      </div>

      <div className="flex h-page-body gap-4 pb-4">
        <div className="w-[45%] gap-3">
          <div className="cursor-hand h-40 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full opacity-50"></div>
          </div>
          <div className="cursor-hand mt-3 h-40 w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full opacity-50"></div>
          </div>
        </div>
        <div className="flex-grow">
          <div className="cursor-hand h-full w-full rounded-lg bg-slate-700/30 text-heading font-medium drop-shadow-lg">
            <div className="shimmer-container relative h-full opacity-50"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortlistSkeleton
