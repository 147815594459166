import React from 'react'

const useBeforeUnload = (
  onBeforeUnload: (event: BeforeUnloadEvent) => void
) => {
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (onBeforeUnload) {
        onBeforeUnload(event)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [onBeforeUnload])
}

export default useBeforeUnload
