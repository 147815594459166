import React from 'react'

import BlockCard from '@components/block-card'

interface ComponentProps {
  isLoaded: boolean
  buildings: Array<string>
  envisionActiveBlocks: Array<string>
  handleClick: (arg: string) => void
}
function BuildingList({
  isLoaded,
  buildings,
  handleClick,
  envisionActiveBlocks,
}: ComponentProps) {
  if (!isLoaded) {
    return (
      <div className="text-4xl text-secondaryColour">
        Loading building data...
      </div>
    )
  }

  if (buildings.length === 0) {
    return (
      <div className="cursor-hand flex w-full items-center justify-between rounded-lg bg-secondaryColour px-4 py-10 text-4xl text-mainColour">
        No building found
      </div>
    )
  }

  return (
    <>
      {buildings.map(
        (building: string) =>
          envisionActiveBlocks?.includes(building) && (
            <BlockCard
              key={building}
              label={building}
              handleClick={() => handleClick(building)}
            />
          )
      )}
    </>
  )
}
export default BuildingList
