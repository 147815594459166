import React, { BaseSyntheticEvent } from 'react'

import { GalleryItemInterface } from '@store/types'

import { PauseSvg, PlaySvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

export interface ComponentPropsInterface {
  galleryData: {
    index: number
    item: GalleryItemInterface
  }
  isActiveItem: boolean
  onItemClick: (index: number, type: string) => void
  showPlayButton: boolean
  setTabWidth?: (width: number) => void
  isVideoPlaying: boolean
}

function GalleryItem({
  galleryData,
  isActiveItem,
  onItemClick,
  showPlayButton,
  setTabWidth,
  isVideoPlaying,
}: ComponentPropsInterface) {
  const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL
  const itemRef = React.useRef<HTMLDivElement>(null)

  const isVideo = (item: GalleryItemInterface) =>
    item.videoSource && item.videoSource !== ''

  const onImageError = (e: BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  return (
    <div
      ref={itemRef}
      className={`rounded-lg shadow-standard ${
        isActiveItem ? 'bg-gradient' : 'cursor-pointer bg-secondaryColour'
      }`}
      onClick={() =>
        onItemClick(
          galleryData.index,
          isVideo(galleryData.item) ? 'video' : 'image'
        )
      }
      role="none"
    >
      <div className="rounded-lg">
        <div className="relative flex h-48 items-center justify-center">
          <img
            className="h-full w-full rounded-b-lg rounded-t-lg object-cover"
            role="none"
            loading="lazy"
            src={
              AssetHandler({
                url: galleryData.item.imageSource,
                type: galleryData.item.assetType || 'legacy',
              }) ?? PLACEHOLDER_IMAGE
            }
            alt={galleryData.item.title}
            onError={onImageError}
            onLoad={() => {
              setTimeout(() => {
                if (itemRef.current?.offsetWidth && setTabWidth) {
                  setTabWidth(itemRef.current.offsetWidth * 2 + 15)
                }
              }, 100)
            }}
          />
          {showPlayButton && isVideo(galleryData.item) && (
            <button
              type="button"
              className={`absolute rounded-full ${
                isActiveItem ? 'bg-gradient' : 'bg-secondaryColour'
              } p-3`}
              onClick={() => onItemClick(galleryData.index, 'video')}
            >
              {isVideoPlaying && isActiveItem ? (
                <PauseSvg
                  className={`h-3 w-3 ${
                    isActiveItem ? 'text-neutralColour' : 'text-mainColour'
                  }`}
                />
              ) : (
                <PlaySvg
                  className={`h-3 w-3 ${
                    isActiveItem ? 'text-neutralColour' : 'text-mainColour'
                  }`}
                />
              )}
            </button>
          )}
        </div>
      </div>
      <div
        title={galleryData.item.title || 'Untitled'}
        className={`truncate p-4 text-default ${
          isActiveItem ? 'text-neutralColour' : 'text-mainColour'
        }`}
      >
        {galleryData.item.title || 'Untitled'}
      </div>
    </div>
  )
}

export default GalleryItem
