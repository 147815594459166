import React from 'react'
import { createPortal } from 'react-dom'

import {
  GalleryItemInterface,
  HorizontalScrollContainerRef,
  PlayerControlInterface,
} from '@store/types'

import HorizontalScrollContainer from '@components/horizontal-scroll-container'
import PlayerControl from '@components/player-control'

import { CloseSvg, PlaySvg } from '@svg/react'

import AssetHandler from '@adUtilities/asset-handler/asset-handler'

export interface ComponentPropsInterface {
  carouselData: {
    index: number
    item: GalleryItemInterface
  }
  carouselItems: GalleryItemInterface[]
  onItemClick: (index: number, type: string) => void
  showEnlargedView: boolean
  onOverlayClick: () => void
  playerSession?: PlayerControlInterface
}

const PLACEHOLDER_IMAGE = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL

const CarouselOverlay = ({
  carouselData,
  carouselItems,
  onItemClick,
  showEnlargedView,
  onOverlayClick,
  playerSession,
}: ComponentPropsInterface) => {
  const horizontalScrollWrapperRef =
    React.useRef<HorizontalScrollContainerRef>()

  const isVideo = (item: GalleryItemInterface) =>
    item?.videoSource && item?.videoSource !== ''

  const onImageError = (e: React.BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  const handleClick = (index: number) => () => {
    if (
      horizontalScrollWrapperRef.current &&
      horizontalScrollWrapperRef.current.isDragging()
    ) {
      return
    }
    onItemClick(index, 'image')
  }

  return createPortal(
    <div
      className={`fixed left-0 top-0 z-30 flex h-screen w-screen flex-col items-center gap-4 bg-black/40 px-10 py-4 transition-opacity ${
        showEnlargedView ? 'opacity-100' : 'pointer-events-none opacity-0'
      }`}
      onClick={(e) => {
        if (e.target !== e.currentTarget) return
        onOverlayClick()
      }}
      role="none"
    >
      <div className="flex h-[78%] w-full flex-1 items-center rounded-lg p-4">
        <div className="relative h-full w-full">
          <button
            className="absolute -top-0 right-2 cursor-pointer text-secondaryColour"
            type="button"
            onClick={() => {
              onOverlayClick()
            }}
          >
            <CloseSvg className="h-6 w-6" />
          </button>
          <img
            className="h-full w-full rounded-lg object-contain px-12"
            role="none"
            loading="lazy"
            src={
              AssetHandler({
                url: carouselData.item?.imageSource,
                type: carouselData.item?.assetType || 'legacy',
                staticUrl: carouselData.item?.config?.staticUrl,
                noSpliceUrl: carouselData.item?.config?.noSpliceUrl,
              }) ?? PLACEHOLDER_IMAGE
            }
            alt={carouselData.item?.title}
            onError={onImageError}
          />
          {isVideo(carouselData.item) &&
            playerSession?.playerState === 'stop' && (
              <button
                type="button"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondaryColour p-3 drop-shadow-md"
                onClick={() => {
                  onItemClick(carouselData.index, 'video')
                }}
              >
                <PlaySvg className="h-3 w-3 text-mainColour" />
              </button>
            )}
        </div>
      </div>

      <div
        className="flex w-full flex-shrink-0 justify-center"
        onClick={(e) => {
          if (e.target !== e.currentTarget) return
          onOverlayClick()
        }}
        role="none"
      >
        <HorizontalScrollContainer
          className="gap-4"
          ref={horizontalScrollWrapperRef}
        >
          {carouselItems.map((item, index) => (
            <div
              key={item.id}
              className="relative h-32 w-32 flex-shrink-0 rounded-lg"
            >
              <img
                className="h-full w-full cursor-pointer select-none rounded-lg object-cover"
                onClick={handleClick(index)}
                role="none"
                loading="lazy"
                src={
                  AssetHandler({
                    url: item.imageSource,
                    type: item.assetType || 'legacy',
                    staticUrl: item?.config?.staticUrl,
                    noSpliceUrl: item?.config?.noSpliceUrl,
                  }) ?? PLACEHOLDER_IMAGE
                }
                alt={item.title}
                onError={onImageError}
                draggable={false}
              />
              {isVideo(item) && (
                <button
                  type="button"
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-secondaryColour p-3 drop-shadow-md"
                  onClick={() => {
                    onItemClick(index, 'video')
                  }}
                >
                  <PlaySvg className="h-3 w-3 text-mainColour" />
                </button>
              )}
            </div>
          ))}
        </HorizontalScrollContainer>
      </div>

      {showEnlargedView && (
        <PlayerControl
          galleryName="unitGallery"
          playerSession={playerSession}
          className="px-20"
        />
      )}
    </div>,
    document.getElementById('root') as Element
  )
}

export default CarouselOverlay
