import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import {
  InteractiveMapItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useParseMapLayerActionRoute from '@adUtilities/map-action-route-util'

import InteractiveMapLayer from './interactive-map-layer'

export interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  itemData: InteractiveMapItemInterface
  onItemClick: (groupId: string) => void
  isActive: boolean
}

function InteractiveMapItem({
  projectIdentity,
  itemData,
  onItemClick,
  isActive,
}: ComponentPropsInterface) {
  const { actionRoute } = itemData
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback(() => {
    onItemClick(itemData.groupId)
  }, [onItemClick, itemData.groupId])

  const handleClickLink = React.useCallback<
    MouseEventHandler<HTMLAnchorElement>
  >(
    async (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (path === 'level-view') {
        await firebaseControlQuery.update({
          [`building.activeBlock`]: params.block || '',
        })
      }
      if (path === 'building') {
        await firebaseControlQuery.update({
          [`building.activeBlock`]: params.block || '',
          [`building.activeLevel`]: params.level || '',
          [`building.activeUnit`]: params.unit || '',
        })
      }
      if (path) {
        firebaseControlQuery.updateRoute(path)
      }
    },
    [path, params]
  )

  return (
    <InteractiveMapLayer
      label={itemData.label}
      path={path}
      isActive={isActive}
      isValid={isValid}
      onClick={handleClick}
      onClickLink={handleClickLink}
    />
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(InteractiveMapItem)
