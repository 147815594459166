import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  InteractiveMapItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import useParseMapLayerActionRoute, {
  Path,
} from '@adUtilities/map-action-route-hnl-util'

import InteractiveMapLayer from './interactive-map-layer'

export interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  itemData: InteractiveMapItemInterface
  onItemClick: (groupId: string) => void
  isActive: boolean
}

function InteractiveMapItemHnl({
  projectIdentity,
  itemData,
  onItemClick,
  isActive,
}: ComponentPropsInterface) {
  const history = useHistory()
  const { actionRoute } = itemData
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const [parseMapActionRoute] = useParseMapLayerActionRoute()
  const { path, params, isValid } = React.useMemo(
    () => parseMapActionRoute(actionRoute),
    [actionRoute, parseMapActionRoute]
  )

  const handleClick = React.useCallback(() => {
    onItemClick(itemData.groupId)
  }, [onItemClick, itemData.groupId])

  const handleClickLink = React.useCallback<
    MouseEventHandler<HTMLAnchorElement>
  >(
    async (e) => {
      e.preventDefault()
      e.stopPropagation()
      await firebaseControlQuery.update({
        [`houseAndLand.activePrecinctId`]: params.precinct || '',
        [`houseAndLand.activeStageId`]: params.stage || '',
        [`houseAndLand.activeLotId`]: params.lot || '',
      })
      if (!path) {
        return
      }
      if (path === Path.Stage && params.lot) {
        firebaseControlQuery.updateRoute(path)
        history.push('lot-view')
        return
      }
      firebaseControlQuery.updateRoute(path)
    },
    [path, params]
  )

  return (
    <InteractiveMapLayer
      label={itemData.label}
      path={path}
      isActive={isActive}
      isValid={isValid}
      onClick={handleClick}
      onClickLink={handleClickLink}
    />
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(InteractiveMapItemHnl)
