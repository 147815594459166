import React from 'react'
import { connect } from 'react-redux'

import { RootStateTypeExtra } from '@store/types'

export interface LoadingIndicatorInterface {
  isLoading: boolean
}

function LoadingIndicator({ isLoading }: LoadingIndicatorInterface) {
  if (!isLoading) return null

  return (
    <div className="fixed inset-0 z-10 bg-secondaryColour bg-opacity-50">
      <div className="absolute left-50% top-[45%] h-24 w-24 animate-spin rounded-full border-4 border-solid border-neutralColour border-t-transparent text-center"></div>
    </div>
  )
}

export default connect(
  ({ statusIndicator: { isLoading } }: RootStateTypeExtra) => ({
    isLoading,
  })
)(LoadingIndicator)
