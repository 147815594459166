import React from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { CSSTransitionProps } from 'react-transition-group/CSSTransition'

import useCurrentPage from '@utilities/location-util'

interface WithPageTransitionProps {
  children: React.ReactNode
}

function WithPageTransition({ children }: WithPageTransitionProps) {
  const location = useLocation()
  const [currentPage, previousPage] = useCurrentPage()
  const transitionProps = React.useMemo(() => {
    const props: CSSTransitionProps = {
      classNames: '',
      timeout: 500,
    }
    const fadingPages = ['vision', 'dashboard', 'login', '']
    const slidingRightPages = ['level-view', 'area-view']
    const slidingLeftPages = ['level-view', 'building']

    if (
      fadingPages.includes(currentPage) ||
      fadingPages.includes(previousPage)
    ) {
      props.classNames = 'transition-fade-ease'
      return props
    }

    if (
      slidingRightPages.includes(currentPage) &&
      slidingLeftPages.includes(previousPage)
    ) {
      props.classNames = 'transition-slide-right'
      return props
    }

    if (
      slidingLeftPages.includes(currentPage) &&
      slidingRightPages.includes(previousPage)
    ) {
      props.classNames = 'transition-slide-left'
      return props
    }

    props.classNames = 'transition-slide-top'
    return props
  }, [location])

  return (
    <TransitionGroup
      childFactory={(child) =>
        React.cloneElement(child, { ...transitionProps })
      }
      className="relative h-screen w-screen overflow-hidden"
    >
      <CSSTransition
        key={location.pathname}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default WithPageTransition
