import React, { MouseEvent } from 'react'

import { PlayerState } from '@store/types'

import { PlaySvg, StopSvg } from '@svg/react'

interface ComponentProps {
  isActive: boolean
  playerState?: PlayerState
  onPlayStopClick: (
    event: MouseEvent<HTMLButtonElement>,
    action: PlayerState
  ) => void
}

function PlayStopButton({
  isActive,
  playerState,
  onPlayStopClick,
}: ComponentProps) {
  const isPlaying = React.useMemo(
    () => isActive && playerState !== PlayerState.Stop,
    [isActive, playerState]
  )

  return (
    <button
      type="button"
      onClick={(event: MouseEvent<HTMLButtonElement>) =>
        onPlayStopClick(event, isPlaying ? PlayerState.Stop : PlayerState.Play)
      }
      className={`flex h-12 w-12 items-center justify-center rounded-full ${
        isPlaying ? 'bg-gradient' : 'bg-secondaryColour'
      }`}
    >
      {isPlaying ? (
        <StopSvg size="m" className="text-neutralColour" />
      ) : (
        <PlaySvg size="m" className="text-mainColour" />
      )}
    </button>
  )
}

export default PlayStopButton
