import { useLocation } from 'react-router-dom'

import { getQueryStringParams } from '@utilities/helper'

const parseJSON = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return ''
  }
}

const parseArray = (str: string) => {
  if (typeof str !== 'string') {
    return []
  }
  const trimmed = str.trim()
  if (!trimmed.startsWith('[') || !trimmed.endsWith(']')) {
    return []
  }
  const content = trimmed.slice(1, -1).trim()
  if (content === '') {
    return []
  }
  return content
    .split(',')
    .map((item) => item.trim().replace(/^['"]|['"]$/g, ''))
}

const LOCALSTORAGE_KEY = 'appConfig'

const useHiddenNavs = () => {
  const location = useLocation()
  const { hideNav } = getQueryStringParams(location.search)
  const projectConfigData = parseJSON(
    localStorage.getItem(LOCALSTORAGE_KEY) || ''
  )
  const hideNavData = parseArray(hideNav || '')

  if (hideNavData.length > 0 || hideNav === '[]') {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify({ ...projectConfigData, hideNav: hideNavData })
    )
    return hideNavData
  }
  return projectConfigData?.hideNav || []
}

export default useHiddenNavs
