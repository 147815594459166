import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import { MenuItemKey } from '@components/navigation/menu-items'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { ProjectType } from '@adUtilities/constants/common'

interface EmptyStateProps {
  projectIdentity: ProjectIdentity
}

function EmptyState({ projectIdentity }: EmptyStateProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const {
    isSnaploaderActive: hasSnaploader,
    isEnvisionVRActive: hasEnvisionVR,
    type,
  } = projectIdentity

  const handleNavigateToResidences = () => {
    if (type === ProjectType.HouseAndLand) {
      firebaseControlQuery.updateRoute('area-view-house-and-land')
      return
    }

    if (hasSnaploader) {
      firebaseControlQuery.updateRoute('snaploader-view')
      return
    }

    if (hasEnvisionVR) {
      firebaseControlQuery.updateRoute('3d-building')
      return
    }

    firebaseControlQuery.updateRoute('area-view')
  }

  const exploreLinkText = React.useMemo(() => {
    const dynamicResidencesText = projectIdentity?.navigationSettings?.find(
      (item) => item.key === MenuItemKey.Residences
    )
    if (dynamicResidencesText && dynamicResidencesText.label) {
      return `Explore ${dynamicResidencesText.label?.toLowerCase()}`
    }
    if (projectIdentity?.type === ProjectType.HouseAndLand) {
      return 'Explore Precinct'
    }
    return 'Explore Residences'
  }, [projectIdentity?.navigationSettings, projectIdentity?.type])

  return (
    <div className="flex w-full flex-col items-center justify-center rounded-lg bg-neutralColour p-6">
      <p className="mb-6 text-heading font-medium">Your Shortlist is empty</p>
      <p className="mb-11 text-default">Add your favourite properties in it.</p>
      <button
        type="button"
        className="rounded-xl bg-gradient px-12 py-4 text-subHeading font-medium text-white shadow-standard"
        onClick={handleNavigateToResidences}
      >
        {exploreLinkText}
      </button>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateTypeExtra) => ({
  projectIdentity,
}))(EmptyState)
